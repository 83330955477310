import React from "react";
import {AppContent, FIRST_VIDEO_ID} from "./Config";
import './ConfigChecker.css';

export default function ConfigChecker(props: {config: AppContent}) {

    const allOneLinerIds = Object.keys(props.config.oneliners);
    let unusedOneLinerIds = [...allOneLinerIds];
    let errorFound = false;
    const generalErrors: string[] = [];
    const checkedScenes = props.config.scenes.map((scene, idx) => {

        // validate
        const sceneErrors: string[] = [];
        const allVideoIds = Object.keys(scene.videos);
        // all videos except the start video should be linked somehow
        let unusedVideoIds = [...allVideoIds];
        // start present?
        const startVideoPresent = allVideoIds.indexOf(FIRST_VIDEO_ID) !== -1;

        if (!startVideoPresent) {
            sceneErrors.push(`No start video found with id '${FIRST_VIDEO_ID}'`);
        } else {
            // remove start from unused list
            unusedVideoIds = unusedVideoIds.filter(e => e !== FIRST_VIDEO_ID);
        }

        for(const videoId in scene.videos) {
            // validate
            const videoPart = scene.videos[videoId];
            // check valid .next
            if (videoPart.next) {
                if (allVideoIds.indexOf(videoPart.next) === -1) {
                    // not found!
                    sceneErrors.push(`video id ${videoPart.next} referenced from ${videoId} '.next' not found in list of videos`)
                } else {
                    // found, remove from list of unlinked
                    unusedVideoIds = unusedVideoIds.filter(e => e !== videoPart.next);
                }
            }
            // check valid .intervention.next
            if (videoPart.intervention?.next) {
                if (allVideoIds.indexOf(videoPart.intervention.next) === -1) {
                    // not found!
                    sceneErrors.push(`video id ${videoPart.intervention.next} referenced from ${videoId} '.intervention.next' not found in list of videos`)
                } else {
                    // found, remove from list of unlinked
                    unusedVideoIds = unusedVideoIds.filter(e => e !== videoPart.intervention?.next);
                }
            }
            // check valid oneliners
            if (videoPart.oneliners) {
                for(const item of videoPart.oneliners) {
                    // is ref a valid one?
                    if (allOneLinerIds.indexOf(item.refId) !== -1) {
                        // found, remove from list of unlinked
                        unusedOneLinerIds = unusedOneLinerIds.filter(e => e !== item.refId);
                    } else {
                        // not a valid ref!
                        sceneErrors.push(`oneliner id ${item.refId} referenced from ${videoId} '.oneliners' not found`);
                    }
                }
            }
            // check if we don't have both 'continueText' and 'next' set
            if (videoPart.intervention && videoPart.continueText) {
                sceneErrors.push(`part ${videoId} has both 'intervention' and 'continueText' set`);
            }
            // check for non empty text
            if (videoPart.intervention) {
                if (videoPart.intervention.text === '') {
                    sceneErrors.push(`part ${videoId} has 'intervention.text' set as empty string`);
                }
            }
            if (videoPart.next && !(videoPart.continueText || videoPart.intervention)) {
                sceneErrors.push(`part ${videoId} has 'next' but not 'continueText' or 'intervention'`);
            }
            if (videoPart.continueText && videoPart.continueText === '') {
                sceneErrors.push(`part ${videoId} has 'continueText' set as empty string`);
            }
        }
        if (unusedVideoIds.length > 0) {
            sceneErrors.push(`Not all videos were referenced, orphans: ` + unusedVideoIds.join(","));
        }


        if (sceneErrors.length > 0) {
            errorFound = true;
        }

        return {
            data: scene,
            checks: {
                errors: sceneErrors
            }
        }
    })
    if (unusedOneLinerIds.length > 0) {
        generalErrors.push(`Not all one liners were referenced, orphans: ` + unusedOneLinerIds.join(","));
        errorFound = true;
    }

    if (errorFound) {
        console.log("ERRORS FOUND IN CONFIG");
        if (generalErrors && generalErrors.length > 0) {
            console.log(generalErrors);
        }
        for (const checkedScene of checkedScenes) {
            if (checkedScene.checks && checkedScene.checks.errors && checkedScene.checks.errors.length > 0) {
                console.log(checkedScene.data.label, checkedScene.checks.errors);
            }
        }

    }

    // debug
    if (errorFound) {
        return <div className='config-checker'>
            {
                generalErrors ?
                    <>
                        <h1>General Errors</h1>
                        {
                            generalErrors.map((err, idx) => <div key={idx}>{err}</div>)
                        }
                    </>
                    :
                    null
            }
            {
                checkedScenes.map(({data, checks}, idx) => {
                    return <div key={idx}>
                        <h1>{data.label}</h1>
                        <div style={{whiteSpace: 'pre-wrap'}}>
                            {JSON.stringify(checks)}
                        </div>
                    </div>
                })
            }
        </div>
    } else {
        return null;
    }
}