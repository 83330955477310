import React from "react";
import videojs, {VideoJsPlayer} from "video.js";
import "video.js/dist/video-js.css";

export const VideoJS = ( props: { options: any; onReady: any; visible: boolean, visibilityLevel: number} ) => {

    const videoRef = React.useRef(null);
    // add | null to the typing to make this an _assignable_ ref
    // https://dev.to/wojciechmatuszewski/mutable-and-immutable-useref-semantics-with-react-typescript-30c9
    const playerRef = React.useRef<VideoJsPlayer | null>(null);
    const { options, onReady } = props;

    React.useEffect(() => {
        // make sure Video.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;

            const player = playerRef.current = videojs(videoElement, options, () => {
                // console.log("player is ready");
                onReady && onReady(player);
            });
            player.playsinline(true);
        } else {
            // you can update player here [update player through props]
            // const player = playerRef.current;
            // player.autoplay(options.autoplay);
            // player.src(options.sources);
        }
    }, [options, videoRef, onReady]);

    // Dispose the Video.js player when the functional component unmounts
    React.useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div data-vjs-player
             style={{visibility: props.visible ? 'visible' : 'hidden', zIndex: props.visibilityLevel}}
             className='video-js-player'>
            <video ref={videoRef} className="video-js vjs-big-play-centered" />
        </div>
    );
}

export default VideoJS;