import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// declare google tag manager dataLayer
declare global {
    interface Window { dataLayer: any[]; }
}

const debug = process.env.REACT_APP_DEBUG === 'true' || process.env.REACT_APP_DEBUG === '1';

ReactDOM.render(
  <React.StrictMode>
      <div className='main-wrap'>
          <App debug={debug} />
          <div className={debug ? 'version-info version-info-debug' : 'version-info'} >{process.env.REACT_APP_VERSION_INFO}</div>
      </div>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
