import VideoJS from "./VideoJS";
import {VideoJsPlayer} from "video.js";
import React, {MutableRefObject, useCallback, useEffect, useMemo, useState} from "react";
import config, {FIRST_VIDEO_ID, genericId, Oneliner, SceneContent} from "./Config";
import InterventionOverlay from "./InterventionOverlay";
import OneLiner from "./OneLiner";
import "./ScenePlayer.css"
import {sendAnalyticsEvent} from "./App";
import useInterval from "./useInterval";

const DEFAULT_INTERVENTION_MILLIS = 5000;
const DEFAULT_OVERLAP_MILLIS = 1; // we just want to know the video has started
const DEFAULT_LEADER_MILLIS = 2000;
const DEFAULT_PROCEED_MILLIS = 300;
const ONE_LINER_DISPLAY_MILLIS = 2500;

// const urlGreen = 'https://player.vimeo.com/external/672274731.m3u8?s=770a0226e802e5bf5fff3c820e648e45d31d881d';
// const urlPurple = 'https://player.vimeo.com/external/672274755.m3u8?s=2d964efe14a31b36ba424482e92bdbfb374f2578'
// const urlBlack = 'https://player.vimeo.com/external/672274786.m3u8?s=31a30c6da0d397274aba6bd5fdb4f92b4b5d454d'
const urlEmptyBlackVideo = 'https://player.vimeo.com/external/691437622.m3u8?s=abe1b3ba2d444b8bb571c621abc4bba38ee7a635';

export interface ClickTriggerable {
    trigger():void;
}

function ScenePlayer(props: {
    playSceneIdx: number|null,
    playSceneStartTime: number,
    clickTriggerable: MutableRefObject<ClickTriggerable|null>,
    onOneLinerCollected: (id: string) => void,
    oneliners: {[key: genericId]: Oneliner},
    onDone: () => void,
    hasNext: boolean,
    onNext: () => void,
    debug: boolean,
}) {
    const playerRefA = React.useRef<VideoJsPlayer | null>(null);
    const playerRefB = React.useRef<VideoJsPlayer | null>(null);
    const playerRefC = React.useRef<VideoJsPlayer | null>(null);
    const playerRefD = React.useRef<VideoJsPlayer | null>(null);

    const activateFunctionRef = React.useRef<string | null>(null);
    const activateFunctionRef2 = React.useRef<number | null>(null);

    const {onOneLinerCollected, hasNext, playSceneIdx, playSceneStartTime, debug} = props;

    const allPlayers = useMemo(() => [playerRefA, playerRefB, playerRefC, playerRefD], [playerRefA, playerRefB, playerRefC, playerRefD]);

    const [activePlayerIdx, setActivePlayerIdx] = useState(2);
    const [oldActivePlayerIdx, setOldActivePlayerIdx] = useState<number|null>(null);
    const [scenePartId, setScenePartId] = useState<string|null>(FIRST_VIDEO_ID);
    const [showLeader, setShowLeader] = useState<boolean>(false);
    const [showInterventionOrContinue, setShowInterventionOrContinue] = useState<0|1|2>(0);
    // const [showIntervention, setShowIntervention] = useState<boolean>(false);
    // const [showContinue, setShowContinue] = useState<boolean>(false);
    const [showEndScreen, setShowEndScreen] = useState<boolean>(false);
    const [showInterventionMillis, setShowInterventionMillis] = useState<number|null>(null);
    const [proceedMillis, setProceedMillis] = useState<number|null>(null);
    const [remainingOnButton, setRemainingOnButton] = useState<number>(0);
    // const [collectedOneLiners, setCollectedOneLiners] = useState<string[]>([]);
    const [oneLinersState, setOneLinersState] = useState<{[key: string]: 'waiting'|'show'|'shown'}>({});

    const currentSceneConfig = useMemo(() => {
            // console.log('updating currentSceneConfig', playSceneIdx, playSceneStartTime);

            //
            // reset various states
            //
            setActivePlayerIdx(2);
            setScenePartId(FIRST_VIDEO_ID);
            setShowLeader(false);
            // setShowIntervention(false);
            // setShowContinue(false);
            setShowInterventionOrContinue(0);
            setShowEndScreen(false);
            setShowInterventionMillis(null);
            setProceedMillis(null);
            setRemainingOnButton(0);
            // setCollectedOneLiners([]);
            setOneLinersState({});

            // get actual scene
            // We 'fake' using playSceneStartTime to keep react from complaining
            // the playSceneStartTime is used as an extern trigger to initialize the player even if it is started
            // a second time for the same scene
            return playSceneIdx !== null && playSceneStartTime ? config.scenes[playSceneIdx] : null
        },
        [playSceneIdx, playSceneStartTime]
        // [playSceneIdx]
    );
    const currentScenePart = useMemo(() => scenePartId !== null ? currentSceneConfig?.videos[scenePartId] : null, [currentSceneConfig, scenePartId]);
    const startAtMillis = useMemo(() => currentScenePart?.video.startAtMillis ?? 0, [currentScenePart]);

    // console.log('rendering with showInterventionMillis', showInterventionMillis);

    const videoJsOptions = { // lookup the options in the docs for more options
        autoplay: false,
        loop: false,
        preload: 'auto',
        // preload: 'metadata',
        responsive: true,
        fluid: true,
        // html5: {
        //     vhs: {
        //         limitRenditionByPlayerDimensions: false,
        //         // smoothQualityChange: true,
        //         // bandwidth: 6194304,
        //         bandwidth: 61943,
        //         overrideNative: true
        //     }
        // },
        sources: [{
            src: urlEmptyBlackVideo,
            type: 'application/x-mpegURL'
        }]
    }
    props.clickTriggerable.current = {
        trigger() {
            if (playerRefA.current && playerRefB.current && playerRefC.current && playerRefD.current) {
                // console.log('trigger video players!');
                playerRefA.current.player().play()
                playerRefB.current.player().play()
                playerRefC.current.player().play()
                playerRefD.current.player().play()
                playerRefA.current.player().pause()
                playerRefB.current.player().pause()
                playerRefC.current.player().pause()
                playerRefD.current.player().pause()
            } else {
                console.log('trigger video players FAILED, players not ready');
            }
        }
    }

    // const updateOldPlayerId = useCallback(
    //     () => {
    //         const oldActiveIdx = activePlayerIdx;
    //         setOldActivePlayerIdx(oldActiveIdx);
    //         return oldActiveIdx;
    //     },
    //     [activePlayerIdx],
    // );
    React.useEffect(() => {
        // console.log('rerunning useEffect', [playSceneIdx, allPlayers, scenePartId]);
        // if (currentSceneConfig === null) {
        //     console.log('currentSceneConfig is null!');
        //     return;
        // }
        function activateNext(currentSceneConfig: SceneContent) {
            if (playerRefA.current && playerRefB.current && playerRefC.current && playerRefD.current && currentScenePart) {
                // console.log('activateNext(): video players available - setting video sources');
                // playerRefA.current.player().pause()
                // playerRefB.current.player().pause()
                // playerRefC.current.player().pause()
                // playerRefD.current.player().pause()

                // videoSrcToActivate
                const videoSrcToActivate = currentScenePart.video.url;

                // check if a videoplayer already has the video 'lined up'
                let newActiveIdx: number|null = null;
                let indexOptions = [0,1,2,3];
                // const oldActiveIdx = updateOldPlayerId();
                const oldActiveIdx = activePlayerIdx;
                setOldActivePlayerIdx(oldActiveIdx);
                // 'reserve' the spot of the old player by removing it from the options array
                indexOptions = indexOptions.filter(function (val) {return val !== oldActiveIdx});
                for (let idx = 0; idx < allPlayers.length - 1; idx += 1) {
                    const currentSrc = allPlayers[idx].current?.player().src()
                    // this is the new video to play
                    if (currentSrc && currentSrc === videoSrcToActivate) {
                        // remove option from array
                        indexOptions = indexOptions.filter(function (val) {return val !== idx});
                        newActiveIdx = idx;
                        // console.log('found queued video, active index', newActiveIdx);
                        break;
                    }
                }
                if (newActiveIdx === null) {
                    // no queued video was found, use first available player
                    const firstAvailableIdx = indexOptions.shift();
                    if (firstAvailableIdx !== undefined) {
                        newActiveIdx = firstAvailableIdx;
                    } else {
                        newActiveIdx = 0;
                    }
                }

                // get other indices by popping from array,
                const newNextIdx = indexOptions[0];
                const newAlternativeIdx = indexOptions[1];

                // console.log('new indices active', newActiveIdx, 'next', newNextIdx, 'alternative', newAlternativeIdx, 'old', oldActiveIdx, indexOptions);

                const hasNext = currentScenePart.next !== undefined;
                const hasIntervention = currentScenePart.intervention !== undefined;

                // set current video
                allPlayers[newActiveIdx].current?.player().src([{
                    src: videoSrcToActivate,
                    type: 'application/x-mpegURL'
                }]);
                if (startAtMillis !== undefined) {
                    allPlayers[newActiveIdx].current?.player().currentTime(startAtMillis / 1000);
                }

                // set appearance of intervention
                // default version if no specific configuration
                let newInterventionMillis = currentScenePart.video.lengthMillis - DEFAULT_INTERVENTION_MILLIS;
                const configuredStartMillis = currentScenePart.showActionAtMillis;
                if (configuredStartMillis !== undefined) {
                    if (configuredStartMillis >= 0) {
                        // positive: millis into the video
                        newInterventionMillis = configuredStartMillis;
                    } else {
                        // negative: millis before end of the video
                        // note: use + to subtract as the value of configuredStartMillis is negative
                        newInterventionMillis = currentScenePart.video.lengthMillis + configuredStartMillis;
                    }
                }
                const newProceedMillis = currentScenePart.video.lengthMillis - DEFAULT_PROCEED_MILLIS;
                // console.log('set newInterventionMillis, newProceedMillis', newInterventionMillis, newProceedMillis);
                setShowInterventionMillis(newInterventionMillis);
                setProceedMillis(newProceedMillis);

                // queue next video
                if (hasNext && currentScenePart.next) {
                    allPlayers[newNextIdx].current?.player().src([{
                        src: currentSceneConfig.videos[currentScenePart.next].video.url,
                        type: 'application/x-mpegURL'
                    }])
                }

                // queue alternative video
                if (hasIntervention && currentScenePart.intervention) {
                    // get video for intervention
                    const interventionVideoUrl = currentSceneConfig.videos[currentScenePart.intervention.next].video.url
                    allPlayers[newAlternativeIdx].current?.player().src([{
                        src: interventionVideoUrl ?? '',
                        type: 'application/x-mpegURL'
                    }])
                }


                // setPlayerMapping({current: newActiveIdx, next: newNextIdx, alternative: newAlternativeIdx}) // mapping of players for next action
                setActivePlayerIdx(newActiveIdx); // player to show
                // store this id to compare to in the next call
                // console.log('set activePlayerIdxRef.current to', newActiveIdx);
                activateFunctionRef.current = scenePartId;

                // setShowIntervention(false);
                // setShowContinue(false);
                setShowInterventionOrContinue(0);
                setShowEndScreen(false);

                // playerRefA.current.player().pause()
                // playerRefB.current.player().pause()
                // playerRefC.current.player().pause()
                // playerRefD.current.player().pause()
                // play!
                for (var i = 0; i < 4; i++) {
                    // console.log('update player idx', i);
                    if (i === newActiveIdx) {
                        // console.log('play', i);
                        allPlayers[i].current?.player().play()
                    } else if (i === oldActiveIdx) {
                        // console.log('ignore (temp pause)', i);
                        allPlayers[i].current?.player().pause()
                        // ignore
                    } else {
                        // console.log('pause', i);
                        allPlayers[i].current?.player().pause()
                    }

                }
            } else {
                // console.log('activateNext(): ERROR - video players not available');
            }
        }
        const start = () => {
            // console.log('start()');
            // const currentSceneConfig = playSceneIdx !== null ? config.scenes[playSceneIdx] : null;
            // if (scenePartId === null) {
            //
            // }
            // setScenePartId(FIRST_VIDEO_ID);
            if (playerRefA.current && playerRefB.current && playerRefC.current && playerRefD.current && currentSceneConfig !== null) {
                // console.log('video players available - setting video sources');
                // only activate if new scenePart _or_ if the playSceneStartTime has changed, indicating a fresh start of the player
                if (scenePartId !== activateFunctionRef.current || activateFunctionRef2.current !== playSceneStartTime) {
                    activateFunctionRef2.current = playSceneStartTime;
                    activateNext(currentSceneConfig);
                }
            } else {
                console.log('ERROR - video players not available');
            }
            setShowEndScreen(false);
            // setShowContinue(false);
            // setShowIntervention(false);
            setShowInterventionOrContinue(0);
        }
        const stopAll = () => {
            // console.log('stopAll()');
            for (const playerRef of allPlayers) {
                if (playerRef.current) {
                    playerRef.current.player().pause()
                }
            }
        }
        if(playSceneIdx === null) {
            // console.log('ScenePlayer: stop playing')
            stopAll();
        } else {
            // console.log('ScenePlayer: play scene idx', playSceneIdx);
            start();
        }
    }, [playSceneIdx, allPlayers, scenePartId, currentSceneConfig, currentScenePart, startAtMillis,
        activePlayerIdx, playSceneStartTime]);

    const activateAlternative = useCallback( () => {
        const newId = currentScenePart?.intervention?.next;
        // setShowIntervention(false);
        // setShowContinue(false);
        setShowInterventionOrContinue(0);
        if (newId) {
            // console.log('activateAlternative called, new scenePartId:', newId);
            setScenePartId(newId);
        } else {
            console.log('activateAlternative called, ERROR new scenePartId not found');
        }

    }, [currentScenePart]);
    const activateContinue = useCallback( () => {
        const newId = currentScenePart?.next;
        // setShowIntervention(false);
        // setShowContinue(false);
        setShowInterventionOrContinue(0);
        if (newId) {
            // console.log('activateContinue called, new scenePartId:', newId);
            setScenePartId(newId);
        } else {
            console.log('activateContinue called, ERROR new scenePartId not found');
        }
    }, [currentScenePart]);

    const handlePlayerReady = (player: VideoJsPlayer, playerId: 'a'|'b'|'c'|'d') => {

        // console.log('player', playerId, 'is ready');

        // player.on('progress', (evt) => {
        //     // console.log('progress', playerId, evt);
        // });
        // player.on('dispose', (evt) => {
        //     // console.log('dispose', playerId, evt);
        //     // TODO should we do cleanup of listeners here? I don't know.
        // })

        if (playerId === 'a') {
            playerRefA.current = player;

            // you can handle player events here
            // player.on('waiting', () => {
            //     console.log('player a is waiting');
            // });
            //
            // player.on('dispose', () => {
            //     console.log('player a will dispose');
            // });
        }

        if (playerId === 'b') {
            playerRefB.current = player;

            // you can handle player events here
            // player.on('waiting', () => {
            //     console.log('player b is waiting');
            // });
            //
            // player.on('dispose', () => {
            //     console.log('player b will dispose');
            // });
        }

        if (playerId === 'c') {
            playerRefC.current = player;

            // you can handle player events here
            // player.on('waiting', () => {
            //     console.log('player c is waiting');
            // });
            //
            // player.on('dispose', () => {
            //     console.log('player c will dispose');
            // });
        }

        if (playerId === 'd') {
            playerRefD.current = player;
        }
    };

    const checker = useCallback(() => {
        const checker = (player: VideoJsPlayer, playerIdx: number) => {
            // console.log('check', player.currentTime() * 1000, showInterventionMillis);
            const currentTimeMillisOnVideo = player.currentTime() * 1000;

            if (currentTimeMillisOnVideo > DEFAULT_OVERLAP_MILLIS && oldActivePlayerIdx !== null) {
                // console.log('stop old player idx:', oldActivePlayerIdx);
                setOldActivePlayerIdx(null);
                // pause play!
                allPlayers[oldActivePlayerIdx].current?.player().pause()
            }

            if (scenePartId === FIRST_VIDEO_ID && currentTimeMillisOnVideo <  DEFAULT_LEADER_MILLIS + startAtMillis) {
                setShowLeader(true);
            } else {
                setShowLeader(false);
            }

            if (currentScenePart && currentScenePart.oneliners) {
                for (const line of currentScenePart.oneliners) {
                    if ((line.timestampMillis + ONE_LINER_DISPLAY_MILLIS) < currentTimeMillisOnVideo) {
                        // hide!
                        setOneLinersState((prevState) => {
                            if (prevState[line.refId] !== 'shown') {
                                // console.log('HIDE ONELINER', line);
                                return {...prevState, [line.refId]: 'shown'}
                            }
                            return prevState;
                        });

                    } else if (line.timestampMillis < currentTimeMillisOnVideo) {
                        // show!
                        setOneLinersState((prevState) => {
                            if (prevState[line.refId] !== 'show') {
                                // console.log('SHOW ONELINER', line);
                                // setCollectedOneLiners(old => [...old, line.refId]);
                                // onOneLinerCollected(line.refId);
                                return {...prevState, [line.refId]: 'show'}
                            }
                            return prevState;
                        });
                    } else {
                        // prepare
                        setOneLinersState((prevState) => {
                            if (!prevState.hasOwnProperty(line.refId)) {
                                // console.log('PREPARE ONELINER', line);
                                return {...prevState, [line.refId]: 'waiting'}
                            }
                            return prevState;
                        });
                    }
                }
            }

            if (showInterventionMillis && (showInterventionMillis < currentTimeMillisOnVideo)) {

                const currentSceneConfig = playSceneIdx !== null ? config.scenes[playSceneIdx] : null;
                if (currentSceneConfig === null) {
                    return;
                }
                if (currentScenePart && currentScenePart.next === undefined) {
                    // if we are playing the alternative ending, show alt end screen?
                    // we've finished the playthrough, show end screen
                    // console.log('check player', playerIdx, player.currentTime() * 1000, showInterventionMillis, 'stop video and show end');
                    for (const playerRef of allPlayers) {
                        if (playerRef.current) {
                            playerRef.current.player().pause();
                        }
                    }
                    const sceneNumber = (playSceneIdx ?? -1) + 1;
                    sendAnalyticsEvent('level_end', {
                        'level_name': `scene ${sceneNumber}`,
                        success: true});
                    setShowEndScreen(true);
                } else {
                    if (currentScenePart && currentScenePart.intervention !== undefined) {
                        // console.log('check player', playerIdx, player.currentTime() * 1000, showInterventionMillis, 'intervention button');
                        // setShowIntervention(true);
                        setShowInterventionOrContinue(1);
                    } else {
                        // console.log('check player', playerIdx, player.currentTime() * 1000, showInterventionMillis, 'next button');
                        // setShowContinue(true);
                        setShowInterventionOrContinue(2);
                    }
                }
                // set 'remaining' on button 1..0
                if (proceedMillis !== null) {
                    const range = proceedMillis - showInterventionMillis;
                    const progress = currentTimeMillisOnVideo - showInterventionMillis;
                    const remaining = Math.max(range - progress, 0) / range;
                    // console.log('countdown: ', range, progress, remaining)
                    setRemainingOnButton(remaining);
                }

            }

            if (proceedMillis && (proceedMillis < currentTimeMillisOnVideo)) {
                // check if hasNext
                // console.log('checker proceedMillis', proceedMillis);
                // console.log('checker scenePartId', scenePartId);
                if (currentScenePart && currentScenePart.next) {
                    // console.log('checker activateContinue()');
                    activateContinue();
                } else {
                    // console.log('checker setShowEndScreen(true)');
                    // Note I don't think we should end up here, as this situation has already been taken care af at showInterventionMillis
                    for (const playerRef of allPlayers) {
                        if (playerRef.current) {
                            playerRef.current.player().pause();
                        }
                    }
                    const sceneNumber = (playSceneIdx ?? -1) + 1;
                    sendAnalyticsEvent('level_end', {
                        'level_name': `scene ${sceneNumber}`,
                        success: true});
                    setShowEndScreen(true);
                }

            }
        }
        // if (!showEndScreen) {
        //     const timer = setInterval(() => {
        //         if (!showEndScreen) {
        //             const player = allPlayers[activePlayerIdx].current?.player();
        //             if (player) {
        //                 checker(player, activePlayerIdx);
        //             }
        //         }
        //     }, 25); // 25ms = 40fps, 50ms = 20fps
        //
        //     return () => clearTimeout(timer);
        // }
        if (!showEndScreen) {
            const player = allPlayers[activePlayerIdx].current?.player();
            if (player) {
                checker(player, activePlayerIdx);
            }
        }
    }, [allPlayers, activePlayerIdx, showInterventionMillis, activateContinue,
        proceedMillis, playSceneIdx, scenePartId, currentScenePart, showEndScreen,
        startAtMillis, oldActivePlayerIdx]);

    useEffect(() => {
        // onOneLinerCollected(line.refId);
        // if (prevState[line.refId] !== 'show') {
        //     // console.log('SHOW ONELINER', line);
        //     // setCollectedOneLiners(old => [...old, line.refId]);
        //     // onOneLinerCollected(line.refId);
        //     return {...prevState, [line.refId]: 'show'}
        // }
        Object.entries(oneLinersState).forEach((entry) => {
            if (entry[1] === 'show') {
                // console.log('onOneLinerCollected', entry[0])
                onOneLinerCollected(entry[0]);
            }
        })
    }, [oneLinersState, onOneLinerCollected])

    useInterval(() => {
        checker();
    }, 25);

    // console.log('ScenePlayer rendered! activePlayerIdx', activePlayerIdx, ' scenePartId:', scenePartId);
    const gradientTop = currentSceneConfig?.style?.gradientTop ?? '#cccccc';
    const gradientBottom = currentSceneConfig?.style?.gradientBottom ?? '#555555';

    const showPlayer = (idx: number) => {
        return idx === activePlayerIdx || idx === oldActivePlayerIdx;
    }
    const playerLevel = (idx: number) => {
        if (idx === oldActivePlayerIdx) {
            return 0;
        }
        if (idx === activePlayerIdx) {
            return -1;
        }
        return -2;
    }
    return (
        <div className="scene-player">
            <VideoJS visible={showPlayer(0)} options={videoJsOptions}
                     visibilityLevel={playerLevel(0)}
                     onReady={(playerRef: VideoJsPlayer) => {handlePlayerReady(playerRef, 'a')}}
            />
            <VideoJS visible={showPlayer(1)} options={videoJsOptions}
                     visibilityLevel={playerLevel(1)}
                     onReady={(playerRef: VideoJsPlayer) => {handlePlayerReady(playerRef, 'b')}}
            />
            <VideoJS visible={showPlayer(2)} options={videoJsOptions}
                     visibilityLevel={playerLevel(2)}
                     onReady={(playerRef: VideoJsPlayer) => {handlePlayerReady(playerRef, 'c')}}
            />
            <VideoJS visible={showPlayer(3)} options={videoJsOptions}
                     visibilityLevel={playerLevel(3)}
                     onReady={(playerRef: VideoJsPlayer) => {handlePlayerReady(playerRef, 'd')}}
            />
            {
                showLeader ?
                    <div className='scene-player__leader'>
                        <div className='scene-player__leader-text'>{currentSceneConfig?.leaderText}</div>
                    </div>
                    :
                    undefined
            }
            {
                // additional check on currentScenePart?.intervention?.text to prevent showing '<kies alternatief>' (bug)
                showInterventionOrContinue === 1 && currentScenePart?.intervention?.text ?
                    <InterventionOverlay onActivate={() => {activateAlternative()}}
                                         onNoAction={() => {
                                             // continue to next video as if there was no interaction
                                             activateContinue();
                                         }}
                                         text={currentScenePart?.intervention?.text ?? '<kies alternatief>'}
                                         remaining={remainingOnButton}
                                         backgroundColor={currentSceneConfig?.style?.interventionBackground ?? '#00000033'}
                                         strokeColor={currentSceneConfig?.style?.baseColor ?? '#ffffff'}
                                         key='intervention-option-1'
                    />
                    :
                    undefined
            }
            {
                showInterventionOrContinue === 2 && currentScenePart?.continueText ?
                    <InterventionOverlay onActivate={() => {activateContinue()}}
                                         onNoAction={() => {activateContinue()}}
                                         text={currentScenePart?.continueText ?? '<vertel verder!>'}
                                         remaining={remainingOnButton}
                                         backgroundColor={currentSceneConfig?.style?.interventionBackground ?? '#00000033'}
                                         strokeColor={currentSceneConfig?.style?.baseColor ?? '#ffffff'}
                                         key='intervention-option-2'
                    />
                    :
                    undefined
            }
            <div className='scene-player__end-screen' style={{
                display: showEndScreen ? 'flex' : 'none',
                background: `linear-gradient(141deg, ${gradientTop} 45%, ${gradientBottom} 45%, ${gradientBottom} 100%)`
            }}>
                <div className='scene-player__end-screen__body-wrap'>
                    <div className='scene-player__end-screen__body'>
                        {currentSceneConfig?.finalText}
                    </div>
                </div>

                <div className='scene-player__end-screen__actions'>
                    <button
                        onClick={() => {props.onDone();}}
                    >Home</button>
                    {hasNext ? <button
                            onClick={() => {
                                // console.log('button onNext()');
                                props.onNext();
                            }}
                        >Volgende</button>
                        :
                        undefined
                    }
                </div>
            </div>
            <button
                onClick={() => {
                    props.onDone();
                }}
                className='scene-player__close'
            >sluit</button>
            <div className='scene-player__one-liners-container'>
                {

                    Object.entries(oneLinersState).map(([key, value]) => {
                        const enter = value === 'show' ? 'enter' : '';
                        const exit = value === 'shown' ? 'exit' : '';
                        return <div className={`scene-player__one-liner-card ${enter} ${exit}`} key={key}>
                        <OneLiner text={props.oneliners[key].text}
                                  style={props.oneliners[key].styling}
                                  mode={0} />
                    </div>})
                }
            </div>
            {
                debug ? <div className='scene-player__debug_video_label'>{currentScenePart?.label}</div> : undefined
            }
        </div>
    );
}

export default ScenePlayer;