import React from "react";
import StartButton from "./StartButton";
import {SceneContent} from "./Config";

const homeGif = 'https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/defe9f96-4203-462e-ab73-3575c3865b7d.gif?ClientID=vimeo-core-prod&Date=1646900853&Signature=9a32163d1095db1a1b74af5d899e2cfecb2836ae';

function SceneSelector(props: {onSelected: (itemIdx: number) => void, scenes: SceneContent[], scenePlayStates: number[]}) {
    // console.log('SceneSelector', props.scenes);
    const selectSceneIdx = (sceneIdx: number) => {
        props.onSelected(sceneIdx);
    }
    return (
        <div className="scene-selector" >
            <div className="player-wrap">
                <img className='scene-selector__loop' alt='' src={homeGif} />
            </div>
            <div className="scene-selector__buttons">
                <div className="scene-selector__start-label"></div>
                <StartButton onClick={() => {
                    selectSceneIdx(3)
                }}
                             storyStyle={props.scenes[3].style ?? {}}
                             state={props.scenePlayStates[3]}
                             label='4'
                             style={props.scenes[3].startButtonPosition}/>
                <StartButton onClick={() => {
                    selectSceneIdx(4)
                }}
                             storyStyle={props.scenes[4].style ?? {}}
                             state={props.scenePlayStates[4]}
                             label='5'
                             style={props.scenes[4].startButtonPosition}/>
                <StartButton onClick={() => {
                    selectSceneIdx(0)
                }}
                             storyStyle={props.scenes[0].style ?? {}}
                             state={props.scenePlayStates[0]}
                             label='1'
                             style={props.scenes[0].startButtonPosition}
                />
                <StartButton onClick={() => {
                    selectSceneIdx(1)
                }}
                             storyStyle={props.scenes[1].style ?? {}}
                             state={props.scenePlayStates[1]}
                             label='2'
                             style={props.scenes[1].startButtonPosition}
                />

                <StartButton onClick={() => {
                    selectSceneIdx(2)
                }}
                             storyStyle={props.scenes[2].style ?? {}}
                             state={props.scenePlayStates[2]}
                             label='3'
                             style={props.scenes[2].startButtonPosition}/>
                <StartButton onClick={() => {
                    selectSceneIdx(5)
                }}
                             storyStyle={props.scenes[5].style ?? {}}
                             state={props.scenePlayStates[5]}
                             label='6'
                             style={props.scenes[5].startButtonPosition}/>
                <StartButton onClick={() => {
                    selectSceneIdx(6)
                }}
                             storyStyle={props.scenes[6].style ?? {}}
                             state={props.scenePlayStates[6]}
                             label='7'
                             style={props.scenes[6].startButtonPosition}/>
                {
                    props.scenes[7] ? // debug scene
                        <StartButton onClick={() => {
                            selectSceneIdx(7)
                        }}
                                     storyStyle={props.scenes[7].style ?? {}}
                                     state={props.scenePlayStates[7]}
                                     label='debug'
                                     style={props.scenes[7].startButtonPosition}/>
                        :
                        undefined
                }
            </div>
        </div>
    );
}
export default SceneSelector;