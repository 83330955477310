import React, {useState} from "react";
import "./OneLinerOverview.css"
import {genericId, Oneliner} from "./Config";
import OneLiner from "./OneLiner";

export default function OneLinerOverview(props: {
    oneliners: {[key: genericId]: Oneliner},
    collectedOneliners: string[],
    debug: boolean
}) {

    const onelinerKeys = Object.keys(props.oneliners);
    const [debugShowAll, setDebugShowAll] = useState(false);

    return <div className='one-liner-overview'>
        {props.debug ?
            <button onClick={() => {
                setDebugShowAll(value => !value)
            }}>debug showall</button>
            :
            undefined
        }
        <div>

        <p className='one-liner-overview__intro'>
            Unlock de oneliners door verschillende keuzes te maken in de video's.
        </p>
            <div className='one-liner-overview__collected'>{props.collectedOneliners.length + '/' + onelinerKeys.length}</div>
            <div className='one-liners-list'>
            {
                onelinerKeys.map((onelinerKey, idx) => {
                    let mode = props.collectedOneliners.indexOf(onelinerKey) !== -1 ? 2 : 1;
                    if (debugShowAll) {
                        mode = 2;
                    }
                    return <div key={idx} >
                        <OneLiner
                            text={props.oneliners[onelinerKey].text} style={props.oneliners[onelinerKey].styling}
                            mode={mode}
                        />
                    </div>
                })
            }
            </div>
        </div>
    </div>
}