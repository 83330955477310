import React, {useEffect, useMemo, useRef, useState} from 'react';
import './App.css';
import ScenePlayer, {ClickTriggerable} from "./ScenePlayer";
import SceneSelector from "./SceneSelector";
import ConfigChecker from "./ConfigChecker";
import config, {VideoPart} from "./Config";
import {useLocalStorage} from "./useLocalStorage";
import OneLinerOverview from "./OneLinerOverview";
import InfoScreen from "./InfoScreen";

const DEFAULT_VIEW_WIDTH = 375;
const DEFAULT_VIEW_HEIGHT = 670;

export const sendAnalyticsEvent = (eventName: string, params: any): void => {
    try {
        // merge event name with params to make it work with the google tag manager
        const eventData = {...params, event: eventName};
        window.dataLayer = window.dataLayer || [];
        // example event: {
        // 'event': 'nieuwe one liner',
        // 'one-liner': 'doe_niet_drugs'
        // }
        window.dataLayer.push(eventData);
    } catch (e) {
        // ignore
    }
}

function App(props: {debug: boolean}) {
    const {debug} = props;
    const [appState, setAppState] = useState(0);
    const [showCollectedAll, setShowCollectedAll] = useState(false);
    const [playScene, setPlayScene] = useState<number | null>(null);
    const [playSceneStartTime, setPlaySceneStartTime] = useState<number>(new Date().getTime());
    const [introShown, setIntroShown] = useLocalStorage('intro-shown', false);
    const [newOneLiners, setNewOneLiners] = useLocalStorage('new-count', 0);
    const [scenePlayStates, setScenePlayStates] = useLocalStorage('play-states', config.scenes.map(() => 0));
    const [collectedOneLiners, setCollectedOneLiners] = useLocalStorage(
        'one-liners',
        Object.keys(config.oneliners).reduce((prev: {[key: string]: boolean}, current) => {prev[current] = false; return prev}, {})
    );

    const scenarioPlayerRef = useRef<ClickTriggerable|null>(null);

    const sceneOneLinerLookup = useMemo(() => {
        return config.scenes.map((sceneContent) => {
            const collect: string[] = [];
            Object.values(sceneContent.videos).forEach((value: VideoPart) => {
                if (value.oneliners) {
                    value.oneliners.forEach((onelinerReference) => {
                        if (collect.indexOf(onelinerReference.refId) === -1) {
                            collect.push(onelinerReference.refId);
                        }
                    });
                }
            });
            return collect;
        })
    }, []);

    useEffect(() => {
        // make a list of all collected one liners
        const collectedOneLinerIds = Object.entries(collectedOneLiners)
            .filter(([key, value]) => value)
            .map(([key, value]) => key)

        // check if all one liners for a specific scene have been collected
        sceneOneLinerLookup.forEach((oneLiners, idx) => {
            if (oneLiners.every((ref) => {
                return collectedOneLinerIds.indexOf(ref) !== -1;
            })) {
                setScenePlayStates((state: number[]) => {
                    if (state[idx] !== 2) { // 2 is 'all one liners collected'
                        const newState = state.slice() // copy the array (needed to trigger re-render)
                        newState[idx] = 2;
                        return newState;
                    }
                    // else, no change
                    return state;
                });
            }
        })
    }, [collectedOneLiners, sceneOneLinerLookup, setScenePlayStates])

    // React.useEffect(() => {
    //         window.addEventListener('popstate', (event) => {
    //             console.log("popstate location: " + document.location + ", state: " + JSON.stringify(event.state));
    //             setAppState(JSON.parse(event.state));
    //         });
    //     },
    //     []
    // );
    // React.useEffect(() => {
    //         window.history.pushState(JSON.stringify(appState), '');
    //     },
    //     [appState]
    // );


    const navigateToState = (state: number): void => {
        // if (state === 0) {
        //     // this is 'back', remove item from stack?
        // } else {
        //     console.log("pushState location: " + document.location + ", state: " + JSON.stringify(state));
        //     window.history.pushState(JSON.stringify(state), '');
        // }
        switch (state) {
            case 0:
                sendAnalyticsEvent('screen_view', {'screen_name' : 'home'} )
                break;
            case 1:
                sendAnalyticsEvent('screen_view', {'screen_name': 'videoPlayer'});
                break;
            case 2:
                sendAnalyticsEvent('screen_view', {'screen_name': 'oneliners'});
                break;
            case 3:
                sendAnalyticsEvent('screen_view', {'screen_name': 'about'});
                break;
        }
        setAppState(state);
    }

    const updateAppScale = () => {
        //scale the app to fit inside the window (contained)
        const heightRatio = window.innerHeight / DEFAULT_VIEW_HEIGHT;
        const widthRatio = window.innerWidth / DEFAULT_VIEW_WIDTH;

        // console.log('set fontsize on root to ', Math.min(heightRatio, widthRatio) + 'px');
        document.documentElement.style.fontSize = Math.min(heightRatio, widthRatio) + 'px';
    }

    useEffect(() => {
        window.addEventListener('resize', updateAppScale);

        // cleanup this component
        return () => {
            window.removeEventListener('resize', updateAppScale);
        };
    });

    // demo / test only
    // React.useEffect(() => {
    //    setTimeout(() => {setShowCollectedAll(true)}, 2000);
    // }, []);

    // trigger once
    updateAppScale();
    // console.log('App rendered!');

    function startScene(selectedIdx: number) {
        const sceneNumber = selectedIdx + 1;
        sendAnalyticsEvent('level_start', {'level_name': `scene ${sceneNumber}`});
        // console.log('starting Scene idx', selectedIdx);
        if (scenarioPlayerRef.current) {
            // console.log('trying to trigger players...');
            scenarioPlayerRef.current?.trigger();
        }
        navigateToState(1);
        setScenePlayStates((state: number[]) => {
            const newState = state.slice() // copy the array (needed to trigger re-render)
            if (newState[selectedIdx] !== 2) { // 2 is 'all one liners collected'
                newState[selectedIdx] = 1;
            }
            return newState;
        });
        setPlaySceneStartTime(new Date().getTime());
        setPlayScene(selectedIdx);
    }

    return (
        <div className={`app state-${appState}`}>
            {/* show/hide, don't fully remove from dom, so we can 'trigger' video elements with a click */}
            <div className="player-wrap" style={{display: appState === 1 ? 'block' : 'none'}}>
                <ScenePlayer playSceneIdx={playScene}
                             playSceneStartTime={playSceneStartTime}
                             clickTriggerable={scenarioPlayerRef}
                             onDone={() => {
                                 navigateToState(0);
                                 setPlayScene(null)
                             }}
                             hasNext={playScene !== null && playScene <= config.scenes.length - 2}
                             onNext={() => {
                                 // console.log('onNext', playScene, config.scenes.length);
                                 if (playScene !== null) {
                                     if (playScene <= config.scenes.length - 2) {
                                         // console.log('startScene', playScene + 1);
                                         startScene(playScene + 1)
                                     } else {
                                         // console.log('startScene', 0);
                                         startScene(0);
                                     }
                                 }
                             }}
                             onOneLinerCollected={(id:string) => {
                                 // console.log('onOneLinerCollected', id);
                                 setCollectedOneLiners((old: { [x: string]: boolean; }) => {
                                     if (!old[id]) {
                                         sendAnalyticsEvent('nieuwe one liner', {
                                             'one-liner': id
                                         });

                                         setNewOneLiners((prev: number) => prev + 1);

                                         const nextState = {...old, [id]: true};
                                         // check if _all_ have been collected
                                         if (Object.values(nextState).every((value) => value)) {
                                             setShowCollectedAll(true);

                                             setTimeout(() => {
                                                 setShowCollectedAll(false);
                                             }, 5 * 1000);
                                         }

                                         return nextState;
                                     } else {
                                         return old
                                     }

                                 });
                             }}
                             oneliners={config.oneliners ?? {}}
                             debug={debug}
                />
            </div>
            <div className='start-wrap' style={{display: appState === 0 || appState === 2 ? 'flex' : 'none'}}>
                <SceneSelector onSelected={(selectedIdx: number) => {
                    startScene(selectedIdx);
                }}
                               scenes={config.scenes}
                               scenePlayStates={scenePlayStates}
                />
                <div className='app__title-background-gradient' />
                <div className='app-title' style={{display: appState === 0 ? 'block' : 'none'}}
                     onClick={() => {navigateToState(3)}}>Festival<br/>Oneliners</div>
                <div className='speaker' style={{display: appState === 0 ? 'block' : 'none'}}
                     onClick={() => {navigateToState(3)}} />
                {
                    navigator.share ?
                        <button className='share-button' onClick={() => {
                            if (navigator.share) {
                                navigator.share({
                                    title: 'Festival Oneliners',
                                    text: 'Unlock de oneliners en zet ze in om je eigen keuze te uiten!',
                                    url: process.env.PUBLIC_URL,
                                });
                            } else {
                                alert('Deze browser ondersteunt geen share optie');
                            }
                        }}>Share</button>
                        :
                        undefined
                }
                <button className='one-liner-button' onClick={() => {
                    if (appState === 2) {
                        navigateToState(0);
                    } else {
                        setNewOneLiners(0)
                        navigateToState(2);
                    }
                }}>Oneliners</button>
                {newOneLiners !== 0 ?
                    <div className={'new-one-liners-notification'}
                         onClick={() => {navigateToState(2)}}>+{newOneLiners}</div>
                    :
                    undefined
                }
            </div>
            <div className='app__one-liner-overview-wrap' style={{display: appState === 2 || appState === 0 ? 'block' : 'none'}}>
                <OneLinerOverview oneliners={config.oneliners}
                                  collectedOneliners={
                                      Object.entries(collectedOneLiners)
                                          .filter(([key, value]) => value)
                                          .map(([key, value]) => key)
                                  }
                                  debug={debug}
                />
            </div>
            <div className='info-wrap' style={{display: appState === 3 ? 'flex' : 'none'}}>
                <InfoScreen onClose={() => {navigateToState(0)}} />
            </div>
            <ConfigChecker config={config} />
            <div className={'app__collected-all' + (showCollectedAll ? ' show' : '')} onClick={() => {setShowCollectedAll(false)}}/>
            {debug ?
                <button
                    className='app__reset-all'
                    onClick={() => {
                        setNewOneLiners(0);
                        setScenePlayStates(config.scenes.map(() => 0));
                        setCollectedOneLiners(Object.keys(config.oneliners).reduce((prev: { [key: string]: boolean }, current) => {
                            prev[current] = false;
                            return prev
                        }, {}));
                    }}>reset all</button>
                :
                undefined
            }
            {/*<InterventionOverlay onActivate={() => {}} text={"hallo Vera,\nwat denk je van\ndeze afstand?"} remaining={0.75} strokeColor={'#ff5500'}*/}
            {/*                     backgroundColor={'#666666'} onNoAction={() => {}} />*/}
            {!introShown ?
                <div className='intro-dialog' onClick={() => {setIntroShown(true);}}/>
                :
                undefined
            }
        </div>
    );
}

export default App;
