import React from "react";
import "./OneLiner.css"
import {StoryStyle} from "./Config";

export default function OneLiner(props: { text: string, style: StoryStyle, mode: number}) {

    // mode:
    // 0 mode 'in movie' -> no backdrop, only one liner
    // 1 mode 'overview not collected' -> desaturated, no border
    // 2 mode 'overview collected' -> backdrop + one liner
    // const showOneLiner = props.mode === 0 || props.mode === 2;
    const showOneLiner = props.mode === 0 || props.mode === 2;
    const showBackdrop = props.mode === 2;
    // console.log(props.style);

    const cssClassLookup = [
        'one-liner-in-movie',
        'one-liner-overview-not-collected',
        'one-liner-overview-collected',
    ]

    return <div className={'one-liner-wrap ' + (cssClassLookup[props.mode] ?? '')}>
        <div className='one-liner-dotted-backdrop' style={{
            borderColor: props.style.oneLinerBg,
            visibility: showBackdrop ? 'visible' : 'hidden'
        }}>{props.text}</div>
        <div className='one-liner' style={{
            borderImageSource: `url(${props.style.oneLinerBorderImageSrc})`,
            visibility: 'visible',
            opacity: showOneLiner ? 1 : 0.2,
        }}>
            <div className='one-liner__label'
                 style={{
                     backgroundColor: props.style.oneLinerBg,
                     color: props.style.oneLinerText,
                 }}
            >{props.text}</div>
        </div>
    </div>
}