import React from "react";
import "./InterventionOverlay.css"

// thanks https://medium.com/hackernoon/a-simple-pie-chart-in-svg-dbdd653b6936
// starting from (1,0) rotating left
// function getCoordinatesForPercent(percent: number) {
//     const x = Math.cos(2 * Math.PI * percent);
//     const y = Math.sin(2 * Math.PI * percent);
//     return [x, y];
// }

// starting from (0,1) rotating left, by adding pi/2
function getCoordinatesForPercent2(percent: number) {
    const x = Math.cos( (-Math.PI / 2) + 2 * Math.PI * percent);
    const y = Math.sin((-Math.PI / 2) + 2 * Math.PI * percent);
    return [x, y];
}

export default function InterventionOverlay(props: {onActivate: () => void, text: string, remaining: number,
    strokeColor: string, backgroundColor: string, onNoAction: () => void}) {
    // const radius = 136.6;
    const radiusForMask = 136.6 + 10;
    const progress = props.remaining;
    const origin = [144.6, 144.6];
    const [startX, startY] = getCoordinatesForPercent2(0);
    const [endX, endY] = getCoordinatesForPercent2(progress);
    // if the slice is more than 50%, take the large arc (the long way around)
    const largeArcFlag = progress > .5 ? 1 : 0;
    // create an array and join it just for code readability
    const startXConverted = (startX * radiusForMask) + origin[0];
    const startYConverted = (startY * radiusForMask) + origin[1];
    const endXConverted = (endX * radiusForMask) + origin[0];
    const endYConverted = (endY * radiusForMask) + origin[1];
    // thanks https://medium.com/hackernoon/a-simple-pie-chart-in-svg-dbdd653b6936
    const pathData = [
        `M ${startXConverted} ${startYConverted}`, // Move
        `A ${radiusForMask} ${radiusForMask} 0 ${largeArcFlag} 1 ${endXConverted} ${endYConverted}`, // Arc
        `L ${origin[0]} ${origin[1]}`, // Line
    ].join(' ');

    return <div className='intervention-overlay'
                style={{backgroundColor: props.backgroundColor}}>
        <div className='intervention-overlay__svg-wrap'
             onClick={() => {props.onActivate();}}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 289.2 289.2" >
                <defs>
                    <style>{`
                    .cls-1 {
                    fill: none;
                    stroke: ${props.strokeColor};
                    stroke-linejoin: round;
                    stroke-width: 16px;
                    stroke-dasharray: 3.99 13.95;
                    /* clip-path: url(#progress-mask); */
                }
                .mask-path {
                    fill: black;
                }
                `}</style>
                    <clipPath id="progress-mask-23">
                        {/*note: color doesn't matter for clipPath, anything drawn will cause the other object to appear*/}
                        <path d={pathData} className='mask-path' />
                    </clipPath>
                </defs>
                <g id="Layer_1-2" data-name="Layer 1">
                    {/*<circle className="cls-1" cx={origin[0]} cy={origin[1]} r={radius} clipPath={'url(#progress-mask-23)'} />*/}
                    <path className="cls-1"
                          d="M281.28,145a137,137,0,0,1-137,137c-181.73-7.22-181.68-266.81,0-274a137,137,0,0,1,137,137Z" clipPath={'url(#progress-mask-23)'}/>
                </g>
            </svg>
            <div className='intervention-overlay__text'>
                {props.text}
                <div className='intervention-overlay__text__underline' style={{borderColor: props.strokeColor}} >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.23 47.42">
                        <defs>
                            <style>{`
                                .cls-touch-hint-1 {
                                fill: ${props.strokeColor};
                            }
                            `}</style>
                        </defs>
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="omschrijving">
                                <g>
                                    <path className="cls-touch-hint-1"
                                          d="M8.66,26.15h0a1.31,1.31,0,0,0,.82-1.65,1.34,1.34,0,0,0-.66-.76,11.12,11.12,0,0,1-5-14.93A11.16,11.16,0,0,1,18.71,3.76a11.23,11.23,0,0,1,6,12.12,1.19,1.19,0,0,0,.39,1.18,1.4,1.4,0,0,0,1.39.21,1.2,1.2,0,0,0,.8-1A14.06,14.06,0,0,0,19.87,1.42,13.76,13.76,0,1,0,7.66,26.08a1.3,1.3,0,0,0,.58.14A1.23,1.23,0,0,0,8.66,26.15Z"/>
                                    <path className="cls-touch-hint-1"
                                          d="M38.63,28.05a39.84,39.84,0,0,0-1.76-5.41c-.29-.76-.55-1.47-.74-2-.8-2.39-3.33-2.4-4.58-2.06L30,19.08a3.14,3.14,0,0,0-1-.74,3.31,3.31,0,0,0-2.4-.07l-1.94.65a3.1,3.1,0,0,0-3.19-.78l-1.79.6-2.3-6.82c-.56-1.65-2.15-3.08-4.93-2.14-2.31.79-3.13,2.51-2.36,5,1,3.36,3.73,11.31,5.46,16.39l-2.32-1a3.43,3.43,0,0,0-4.55,1.29c-1.27,2,.11,4.06,1.32,4.93L23.38,47.14a1.31,1.31,0,0,0,.82.28,1.23,1.23,0,0,0,.42-.07l13.72-4.63a1.34,1.34,0,0,0,.89-1.27C39.23,39.76,39.21,31.16,38.63,28.05Zm-20,6.34A1.29,1.29,0,0,0,19,33c0-.11-4.87-14.28-6.36-19-.3-1-.32-1.37.71-1.72,1.19-.4,1.45,0,1.61.5l4.11,12.16a1.29,1.29,0,0,0,.66.75,1.27,1.27,0,0,0,1,.07,1.34,1.34,0,0,0,.76-.66,1.26,1.26,0,0,0,.06-1l-1-2.87,1.75-.6c.45-.13.55.15.6.29l.85,2.54a1.32,1.32,0,0,0,1.66.82,1.32,1.32,0,0,0,.82-1.66l-.44-1.3,1.72-.58h0a.64.64,0,0,1,.41,0,.78.78,0,0,1,.32.48L29,23.38a1.3,1.3,0,0,0,1.66.82,1.25,1.25,0,0,0,.75-.66,1.29,1.29,0,0,0,.07-1l-.37-1.08,1.18-.4s1.12-.3,1.35.39c.18.52.42,1.17.68,1.87l.09.23a39.65,39.65,0,0,1,1.64,5c.47,2.52.54,9.42.55,12L24.46,44.65,11.65,34.33s-1.1-.84-.7-1.48a.76.76,0,0,1,.68-.44,1.45,1.45,0,0,1,.6.15c1.2.53,4.89,2,5,2.09A1.28,1.28,0,0,0,18.66,34.39Z"/>
                                </g>
                            </g>
                        </g>
                    </svg>

                </div>
            </div>
        </div>
        <div className='intervention-overlay__stay-silent'
             onClick={() => {props.onNoAction();}}
        >zeg niks</div>
    </div>
}