import React, {CSSProperties} from "react";
import "./StartButton.css"
import {StoryStyle} from "./Config";

export default function StartButton(props: {onClick: () => void, style: CSSProperties, label: string,
    storyStyle: StoryStyle, state: number }) {

    const btnLine = props.storyStyle?.buttonLine ?? '#000000';
    const btnBg1 = props.storyStyle?.buttonBg1 ?? '#cccccc';
    const btnBg2 = props.storyStyle?.buttonBg2 ?? '#aaaaaa';

    const arrowFill = props.state === 0 ? btnBg1 : btnLine;

    const play = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.85 45.85">
        <defs>
            <style>{`
                .cls-start-1 {
                /* fill: ${btnBg2}; */
            }

                .cls-start-1, .cls-start-3, .cls-start-4 {
                /* stroke: ${btnLine}; */
                stroke-linejoin: round;
            }

                .cls-start-1, .cls-start-3 {
                stroke-width: 3px;
            }

                .cls-start-2 {
                /* fill: ${btnBg1}; */
            }

                .cls-start-3 {
                fill: none;
            }

                .cls-start-4 {
                stroke-linecap: round;
                stroke-width: 2px;
            }
            `}</style>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="UI">
                <g>
                    <g>
                        <path className="cls-start-1"
                              fill={btnBg2}
                              stroke={btnLine}
                              d="M37.23,11.88c-.1-.09-1.48-1.43-3.14-3.07L6.94,32.18,12.58,38A18,18,0,1,0,37.23,11.88Z"/>
                        <g>
                            <circle className="cls-start-2" cx="19.5" cy="19.5" r="18" fill={btnBg1}/>
                            <circle className="cls-start-3" cx="19.5" cy="19.5" r="18" stroke={btnLine}/>
                        </g>
                    </g>
                    <polygon className="cls-start-4" points="14.61 27.63 14.61 11.38 28.39 19.5 14.61 27.63"
                             stroke={btnLine}
                             fill={arrowFill}
                    />
                </g>
            </g>
        </g>
    </svg>;

    // const played = 'fill middle'
    const complete = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.05 45.05">
        <defs>
            <style>{`
                .cls-complete-1 {
                /* fill: #0f5c60; */
            }

                .cls-complete-1, .cls-complete-3, .cls-complete-4 {
                /* stroke: #053f4e; */
                stroke-linejoin: round;
            }

                .cls-complete-1, .cls-complete-3 {
                stroke-width: 3px;
            }

                .cls-complete-2 {
                /* fill: #32d0cb; */
            }

                .cls-complete-3, .cls-complete-4 {
                fill: none;
            }

                .cls-complete-4 {
                stroke-width: 6px;
            }
            `}</style>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
            <g id="UI">
                <g>
                    <g>
                        <path className="cls-complete-1"
                              d="M37.23,11.88c-.1-.09-1.48-1.43-3.14-3.07L6.94,32.18,12.58,38A18,18,0,1,0,37.23,11.88Z"
                              fill={btnBg2}
                              stroke={btnLine}/>
                        <g>
                            <circle className="cls-complete-2" cx="19.5" cy="19.5" r="18" fill={btnBg1}/>
                            <circle className="cls-complete-3" cx="19.5" cy="19.5" r="18" stroke={btnLine}/>
                        </g>
                    </g>
                    <polyline className="cls-complete-4" points="9.31 16.86 17.59 25.58 37.02 3.96" stroke={btnLine} />
                </g>
            </g>
        </g>
    </svg>

    const background = props.state === 2 ? complete : play;

    // note: class start-button met daarbinnen een button element wordt gebruikt in analytics van rooza, niet zomaar wijzigen!
    return <div className='start-button' style={props.style}>
        {background}
        <button onClick={() => {props.onClick();}} data-label={`scene ${props.label}`}></button>
    </div>
}