import oneLinerBg_1_2 from './assets/one-liner-bg-1-2.png';
import oneLinerBg_3_6 from './assets/one-liner-bg-3-6.png';
import oneLinerBg_4 from './assets/one-liner-bg-4.png';
import oneLinerBg_5 from './assets/one-liner-bg-5.png';
import oneLinerBg_7 from './assets/one-liner-bg-7.png';

export interface Clip {
    url: string;
    startAtMillis?: number;
    lengthMillis: number;
}
export interface Oneliner {
    text: string;
    styling: StoryStyle;
}
export interface OnelinerReference {
    refId: string;
    timestampMillis: number;
}
export type genericId = string;
export interface VideoPart {
    label: string;
    video: Clip;
    oneliners?: OnelinerReference[]
    continueText?: string;
    intervention?: {
        text: string;
        next: genericId;
    }
    next?: genericId;
    showActionAtMillis?: number;
}
export interface StoryStyle {
    baseColor: string;
    interventionBackground: string;

    oneLinerText?: string;
    // oneLinerEdges?: string;
    oneLinerBg?: string;
    oneLinerBorderImageSrc?: string;
    // oneLinerSide?: string;
    // oneLinerBottom?: string;
    gradientTop?: string;
    gradientBottom?: string;
    endScreenButtonFg?: string;

    buttonLine?: string;
    buttonBg1?: string;
    buttonBg2?: string;
}
export interface SceneContent {
    label: string;
    leaderText: string;
    finalText: string;
    style: StoryStyle;
    videos: {[key: genericId]: VideoPart};
    startButtonPosition: {left: string, top: string},
}
export interface AppContent {
    oneliners: {[key: genericId]: Oneliner};
    scenes: SceneContent[];
}

// const urlGreen = 'https://player.vimeo.com/external/672274731.m3u8?s=770a0226e802e5bf5fff3c820e648e45d31d881d';
// const urlPurple = 'https://player.vimeo.com/external/672274755.m3u8?s=2d964efe14a31b36ba424482e92bdbfb374f2578'
// const urlBlack = 'https://player.vimeo.com/external/672274786.m3u8?s=31a30c6da0d397274aba6bd5fdb4f92b4b5d454d'

export const FIRST_VIDEO_ID = 'm1';

const styles: {[key: genericId]: StoryStyle} = {
    'scene1': {
        baseColor: '#AA5AFF',
        interventionBackground: '#1C004933', // 33 = 20%

        oneLinerText: '#FEE7C5',
        oneLinerBg: '#8E39D8',
        oneLinerBorderImageSrc: oneLinerBg_1_2,

        gradientTop: '#2C0066',
        gradientBottom: '#1C0049',
        endScreenButtonFg: '#00284C',

        buttonLine: '#2C0066',
        buttonBg1: '#8E39D8',
        buttonBg2: '#5400AD',
    },
    // 'scene2': {
    //     baseColor: '#AA5AFF',
    //     oneLinerText: '#FEE7C5',
    //     // oneLinerEdges: '#2B1366',
    //     oneLinerBg: '#8E39D8',
    //     oneLinerBorderImageSrc: oneLinerBg_1_2,
    //     // oneLinerSide: '#9400AD',
    //     // oneLinerBottom: '#5400AD',
    //     gradientTop: '#2C0066',
    //     gradientBottom: '#1C0049',
    //
    //     buttonLine: '#2C0066',
    //     buttonBg1: '#8E39D8',
    //     buttonBg2: '#5400AD',
    // },
    'scene3': {
        baseColor: '#41FDEE',
        interventionBackground: '#053F4E33',

        oneLinerText: '#063F4E',
        // oneLinerEdges: '#063F4E',
        oneLinerBg: '#32D0CB',
        oneLinerBorderImageSrc: oneLinerBg_3_6,
        // oneLinerSide: '#107F60',
        // oneLinerBottom: '#105C60',
        gradientTop: '#053F4E',
        gradientBottom: '#00333F',

        buttonLine: '#053F4E',
        buttonBg1: '#32D0CB',
        buttonBg2: '#0F5C60',
    },
    'scene4': {
        baseColor: '#FF6315',
        interventionBackground: '#870D0033',

        oneLinerText: '#FEE7C5',
        // oneLinerEdges: '#063F4E',
        oneLinerBg: '#FF6315',
        oneLinerBorderImageSrc: oneLinerBg_4,
        // oneLinerSide: '#107F60',
        // oneLinerBottom: '#105C60',
        gradientTop: '#C63502',
        gradientBottom: '#A62100', // screenshot colorpicked, original uses transparency

        buttonLine: '#560963',
        buttonBg1: '#FF6315',
        buttonBg2: '#C63502',
    },
    'scene5': {
        baseColor: '#F90248',
        interventionBackground: '#770A3E33',

        oneLinerText: '#FEE7C5',
        // oneLinerEdges: '#063F4E',
        oneLinerBg: '#F90248',
        oneLinerBorderImageSrc: oneLinerBg_5,
        // oneLinerSide: '#107F60',
        // oneLinerBottom: '#105C60',
        gradientTop: '#770A3E',
        gradientBottom: '#630034',

        buttonLine: '#770A3E',
        buttonBg1: '#F90248',
        buttonBg2: '#A71352',
    },
    // 'scene6': {
    //     baseColor: '#41FDEE',
    //     oneLinerText: '#063F4E',
    //     // oneLinerEdges: '#063F4E',
    //     oneLinerBg: '#32D0CB',
    //     oneLinerBorderImageSrc: oneLinerBg_3_6,
    //     // oneLinerSide: '#107F60',
    //     // oneLinerBottom: '#105C60',
    //     gradientTop: '#053F4E',
    //     gradientBottom: '#00333F',
    //
    //     buttonLine: '#053F4E',
    //     buttonBg1: '#32D0CB',
    //     buttonBg2: '#0F5C60',
    // },
    'scene7': {
        baseColor: '#50FB42',
        interventionBackground: '#00432633', // 30%!

        oneLinerText: '#053F4E',
        // oneLinerEdges: '#053F4E',
        oneLinerBg: '#9DFF8F',
        oneLinerBorderImageSrc: oneLinerBg_7,
        // oneLinerSide: '#44B282',
        // oneLinerBottom: '#44B230',
        gradientTop: '#14661D',
        gradientBottom: '#004326',

        buttonLine: '#14661D',
        buttonBg1: '#9DFF8F',
        buttonBg2: '#44B230',
    },
}

/*
Scene 1 upload:
FestivalVerleiding-scene-1-chat-animatie_00_intro
oud: https://player.vimeo.com/external/685796965.m3u8?s=bda31184a6196da157c3abb78d1cc120b3a05f65
https://player.vimeo.com/external/691358630.m3u8?s=888f2363830b730a5142aae875655122a6d7a6cd

FestivalVerleiding-scene-1-chat-animatie_01A_antwoord1
oud: https://player.vimeo.com/external/685797115.m3u8?s=8a86f7a2ca5e393d50040db862b0eeefe18643ea
https://player.vimeo.com/external/691358668.m3u8?s=370645b772e67b846232c20666000068c2e40e9c

FestivalVerleiding-scene-1-chat-animatie_01B_antwoord1
oud: https://player.vimeo.com/external/685797275.m3u8?s=6a624fd0c42e03604ba9065f7f855b9b36355143
https://player.vimeo.com/external/691386444.m3u8?s=0e8db2530c85465c67e13abd66a09f92c9ad360e

FestivalVerleiding-scene-1-chat-animatie_02A_antwoord2
https://player.vimeo.com/external/685797473.m3u8?s=f8e22e320cf2b2deb81b5c202c1b1a46f216ec53

FestivalVerleiding-scene-1-chat-animatie_02B_antwoord2
https://player.vimeo.com/external/685797538.m3u8?s=e6632ab4b6a8231b267a2be07ba1db0c811c7169

Tijden:
./FestivalVerleiding-scene-1-chat-animatie_00_intro.mp4
00:00:30.02
./FestivalVerleiding-scene-1-chat-animatie_01B_antwoord1.mp4
00:00:49.00
./FestivalVerleiding-scene-1-chat-animatie_01A_antwoord1.mp4
00:00:52.01
./FestivalVerleiding-scene-1-chat-animatie_02B_antwoord2.mp4
00:00:16.02
./FestivalVerleiding-scene-1-chat-animatie_02A_antwoord2.mp4
00:00:22.02
 */
const story1: { [key: genericId]: VideoPart } = {
    'm1': { // FestivalVerleiding-scene-1-chat-animatie_00_intro
        label: 'FestivalVerleiding-scene-1-chat-animatie_00_intro',
        video: {
            url: 'https://player.vimeo.com/external/691358630.m3u8?s=888f2363830b730a5142aae875655122a6d7a6cd',
            startAtMillis: 3 * 1000,
            lengthMillis: 30.02 * 1000
        },
        next: 'm1.n',
        intervention: {
            text: "Ik heb een redelijk grote \n3 persoons tent.",
            next: 'm1.a'
        },
    },
    'm1.n': { // FestivalVerleiding-scene-1-chat-animatie_01B_antwoord1
        label: 'FestivalVerleiding-scene-1-chat-animatie_01B_antwoord1',
        video: {
            url: 'https://player.vimeo.com/external/691386444.m3u8?s=0e8db2530c85465c67e13abd66a09f92c9ad360e',
            startAtMillis: 0,
            lengthMillis: 49.00 * 1000
        },
        intervention: {
            text: 'Ik ga ook lekker zonder drugs.',
            next: 'm1.n.a'
        },
        next: 'm1.n.n',
        oneliners: [
            {refId: 'doe_niet_drugs', timestampMillis: 33 * 1000},
        ],
    },
    'm1.a': {   // FestivalVerleiding-scene-1-chat-animatie_01A_antwoord1
        label: 'FestivalVerleiding-scene-1-chat-animatie_01A_antwoord1',
        video: {
            url: 'https://player.vimeo.com/external/691358668.m3u8?s=370645b772e67b846232c20666000068c2e40e9c',
            startAtMillis: 0,
            lengthMillis: 52.01 * 1000
        },
        intervention: {
            text: 'Ik ga ook lekker zonder drugs.',
            next: 'm1.a.a'
        },
        next: 'm1.a.n',
        oneliners: [
            {refId: 'doe_niet_drugs', timestampMillis: 33 * 1000},
        ],
    },
    'm1.n.a': { // FestivalVerleiding-scene-1-chat-animatie_02A_antwoord2
        label: 'FestivalVerleiding-scene-1-chat-animatie_02A_antwoord2',
        video: {
            url: 'https://player.vimeo.com/external/685797473.m3u8?s=f8e22e320cf2b2deb81b5c202c1b1a46f216ec53',
            startAtMillis: 0,
            lengthMillis: 22.02 * 1000
        },
        oneliners: [
            {refId: 'lekker_zonder_drugs', timestampMillis: 5 * 1000},
        ],
        showActionAtMillis: -1
    },
    'm1.n.n': { // FestivalVerleiding-scene-1-chat-animatie_02B_antwoord2
        label: 'FestivalVerleiding-scene-1-chat-animatie_02B_antwoord2',
        video: {
            url: 'https://player.vimeo.com/external/685797538.m3u8?s=e6632ab4b6a8231b267a2be07ba1db0c811c7169',
            startAtMillis: 0,
            lengthMillis: 16.02 * 1000
        },
        showActionAtMillis: -1
    },
    'm1.a.a': { // FestivalVerleiding-scene-1-chat-animatie_02A_antwoord2
        label: 'FestivalVerleiding-scene-1-chat-animatie_02A_antwoord2',
        video: {
            url: 'https://player.vimeo.com/external/685797473.m3u8?s=f8e22e320cf2b2deb81b5c202c1b1a46f216ec53',
            startAtMillis: 0,
            lengthMillis: 22.02 * 1000
        },
        oneliners: [
            {refId: 'lekker_zonder_drugs', timestampMillis: 5 * 1000},
        ],
        showActionAtMillis: -1
    },
    'm1.a.n': { // FestivalVerleiding-scene-1-chat-animatie_02B_antwoord2
        label: 'FestivalVerleiding-scene-1-chat-animatie_02B_antwoord2',
        video: {
            url: 'https://player.vimeo.com/external/685797538.m3u8?s=e6632ab4b6a8231b267a2be07ba1db0c811c7169',
            startAtMillis: 0,
            lengthMillis: 16.02 * 1000
        },
        showActionAtMillis: -1
    },
}
/*

Scene 2 upload:

FestivalVerleiding-scene-2-deel-3_Final_07022022
https://player.vimeo.com/external/674472731.m3u8?s=26dc4ffc7028922262a5516f1ac6c19cddef2017

FestivalVerleiding-scene-2-deel-2_Final_07022022
https://player.vimeo.com/external/674472812.m3u8?s=866dc66eb4a3a135a707df11c3f93748ab0f12d1

FestivalVerleiding-scene-2-deel-1_Final_07022022
https://player.vimeo.com/external/674473128.m3u8?s=8ac9b3ee3c3a48e7b54e072542249f7e72d4c9a5

FestivalVerleiding-scene-2-Afronding-3_Final_07022022
https://player.vimeo.com/external/674473272.m3u8?s=546cc5d200d896b9c79f6ecb12467d2ccf7a6f7c

FestivalVerleiding-scene-2-Afronding-2_Final_07022022
https://player.vimeo.com/external/674473453.m3u8?s=2e7736bb96fd52d4fd4c2b3c8ec3a2a3d95932e9

FestivalVerleiding-scene-2-Afronding-1_Final_07022022
https://player.vimeo.com/external/674473562.m3u8?s=6542da362075780beda72650a1e8202cdc397c1b

./FestivalVerleiding-scene-2-Afronding-1_Final_07022022.mp4
00:00:24.79
./FestivalVerleiding-scene-2-Afronding-2_Final_07022022.mp4
00:00:27.80
./FestivalVerleiding-scene-2-deel-3_Final_07022022.mp4
00:00:16.55
./FestivalVerleiding-scene-2-deel-2_Final_07022022.mp4
00:00:46.36
./FestivalVerleiding-scene-2-deel-1_Final_07022022.mp4
00:00:19.80
./FestivalVerleiding-scene-2-Afronding-3_Final_07022022.mp4
00:00:36.95
 */
const story2: { [key: genericId]: VideoPart } = {
    'm1': { // FestivalVerleiding-scene-2-deel-1_Final_07022022
        label: 'FestivalVerleiding-scene-2-deel-1_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/674473128.m3u8?s=8ac9b3ee3c3a48e7b54e072542249f7e72d4c9a5',
            startAtMillis: 3500,
            lengthMillis: 19.80 * 1000
        },
        continueText: "Yes! De line-up is echt geweldig.",
        next: 'm1.n',
        showActionAtMillis: -3.5 * 1000
    },
    'm1.n': { // FestivalVerleiding-scene-2-deel-2_Final_07022022
        label: 'FestivalVerleiding-scene-2-deel-2_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/674472812.m3u8?s=866dc66eb4a3a135a707df11c3f93748ab0f12d1',
            startAtMillis: 3000,
            lengthMillis: 46.36 * 1000
        },
        intervention: {
            text: 'Hmmmm, ik weet het niet.',
            next: 'm1.n.a'
        },
        next: 'm1.n.n',
    },
    'm1.n.a': { // FestivalVerleiding-scene-2-Afronding-1_Final_07022022
        label: 'FestivalVerleiding-scene-2-Afronding-1_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/674473562.m3u8?s=6542da362075780beda72650a1e8202cdc397c1b',
            startAtMillis: 1000,
            lengthMillis: 24.79 * 1000
        },
        showActionAtMillis: 16 * 1000,
    },
    'm1.n.n': { // FestivalVerleiding-scene-2-deel-3_Final_07022022
        label: 'FestivalVerleiding-scene-2-deel-3_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/674472731.m3u8?s=26dc4ffc7028922262a5516f1ac6c19cddef2017',
            startAtMillis: 3000,
            lengthMillis: 16.55 * 1000
        },
        intervention: {
            text: 'Ik wil liever fit zijn voor de finale.',
            next: 'm1.n.n.a'
        },
        oneliners: [
            {refId: 'fit_voor_team', timestampMillis: 5 * 1000},
        ],
        next: 'm1.n.n.n',
    },
    'm1.n.n.a': { // FestivalVerleiding-scene-2-Afronding-2_Final_07022022
        label: 'FestivalVerleiding-scene-2-Afronding-2_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/674473453.m3u8?s=2e7736bb96fd52d4fd4c2b3c8ec3a2a3d95932e9',
            startAtMillis: 4000,
            lengthMillis: 27.80 * 1000
        },
        showActionAtMillis: 19 * 1000,
    },
    'm1.n.n.n': { // FestivalVerleiding-scene-2-Afronding-3_Final_07022022
        label: 'FestivalVerleiding-scene-2-Afronding-3_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/674473272.m3u8?s=546cc5d200d896b9c79f6ecb12467d2ccf7a6f7c',
            startAtMillis: 3000,
            lengthMillis: (36.95 * 1000)
        },
        oneliners: [
            {refId: 'geen_dinsdagdip', timestampMillis: 17 * 1000}
        ],
        showActionAtMillis: 28 * 1000,
    }
};

/*
FestivalVerleiding-scene-3-afronding-1_Final_07022022.mp4
https://player.vimeo.com/external/684287099.m3u8?s=8d32ed3653811e1b3e9e9c6783184437bb9ae780

FestivalVerleiding-scene-3-afronding-2_Final_07022022.mp4
https://player.vimeo.com/external/684287032.m3u8?s=18c9f08645a82cdc1622276bbf550f6d696c1ad1

FestivalVerleiding-scene-3-afronding-3_Final_07022022.mp4
https://player.vimeo.com/external/684286922.m3u8?s=079f7a66bf07b90ab5e8b7903b0503494ca34fa9

FestivalVerleiding-scene-3-deel-1_Final_07022022.mp4
https://player.vimeo.com/external/684286819.m3u8?s=bd441ef4f9989f7c19deeeb069f7adf593003b1c

FestivalVerleiding-scene-3-deel-2_Final_07022022.mp4
https://player.vimeo.com/external/684286745.m3u8?s=1e096c3bb309f4bff4cd5e0d8b6c0ed6094289bd

FestivalVerleiding-scene-3-deel-3_Final_07022022.mp4
https://player.vimeo.com/external/684286632.m3u8?s=b4adf5371be474a469be2594d9f13d489ed8a515

FestivalVerleiding-scene-3-deel-4_Final_07022022.mp4
https://player.vimeo.com/external/684286553.m3u8?s=1a2137c9133a8ab098c75b2b99a2ce9c3c0817ec

./FestivalVerleiding-scene-3-deel-4_Final_07022022.mp4
00:00:10.94
./FestivalVerleiding-scene-3-deel-1_Final_07022022.mp4
00:00:20.07
./FestivalVerleiding-scene-3-afronding-1_Final_07022022.mp4
00:00:17.83
./FestivalVerleiding-scene-3-afronding-2_Final_07022022.mp4
00:00:17.66
./FestivalVerleiding-scene-3-deel-2_Final_07022022.mp4
00:00:11.16
./FestivalVerleiding-scene-3-afronding-3_Final_07022022.mp4
00:00:24.55
./FestivalVerleiding-scene-3-deel-3_Final_07022022.mp4
00:00:16.15
 */
const story3: { [key: genericId]: VideoPart } = {
    'm1': { // FestivalVerleiding-scene-3-deel-1_Final_07022022
        label: 'FestivalVerleiding-scene-3-deel-1_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684286819.m3u8?s=bd441ef4f9989f7c19deeeb069f7adf593003b1c',
            startAtMillis: 3500,
            // lengthMillis: (20.07 * 1000) - 1000
            lengthMillis: (20.07 * 1000)
        },
        continueText: "Eens, dat is niks voor mij.",
        next: 'm1.n',
        showActionAtMillis: -4000,
    },
    'm1.n': { // FestivalVerleiding-scene-3-deel-2_Final_07022022
        label: 'FestivalVerleiding-scene-3-deel-2_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684286745.m3u8?s=1e096c3bb309f4bff4cd5e0d8b6c0ed6094289bd',
            startAtMillis: 4500,
            lengthMillis: 11.16 * 1000
        },
        continueText: "O joh, ik dacht dat je naar de wc moest.",
        next: 'm1.n.n',
        showActionAtMillis: -3500,
    },
    'm1.n.n': { // FestivalVerleiding-scene-3-deel-3_Final_07022022
        label: 'FestivalVerleiding-scene-3-deel-3_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684286632.m3u8?s=b4adf5371be474a469be2594d9f13d489ed8a515',
            startAtMillis: 3000,
            lengthMillis: (16.15 * 1000) - 1000
        },
        intervention: {
            text: 'Nee dank je, ik ga naar de wc.',
            next: 'm1.n.n.a'
        },
        next: 'm1.n.n.n',
        showActionAtMillis: 11000,
    },
    'm1.n.n.a': { // FestivalVerleiding-scene-3-afronding-1_Final_07022022
        label: 'FestivalVerleiding-scene-3-afronding-1_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684287099.m3u8?s=8d32ed3653811e1b3e9e9c6783184437bb9ae780',
            startAtMillis: 0,
            lengthMillis: 17.83 * 1000
        },
        oneliners: [
            {refId: 'nee_dank_je', timestampMillis: 0.5 * 1000},
        ],
        showActionAtMillis: 10 * 1000,
    },
    'm1.n.n.n': { // FestivalVerleiding-scene-3-deel-4_Final_07022022
        label: 'FestivalVerleiding-scene-3-deel-4_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684286553.m3u8?s=1a2137c9133a8ab098c75b2b99a2ce9c3c0817ec',
            startAtMillis: 4000,
            lengthMillis: 10.94 * 1000
        },
        intervention: {
            text: 'Ik wil de optredens straks bewust meepakken.',
            next: 'm1.n.n.n.a'
        },
        next: 'm1.n.n.n.n',
        showActionAtMillis: -3500,
    },
    'm1.n.n.n.a': { // FestivalVerleiding-scene-3-afronding-2_Final_07022022
        label: 'FestivalVerleiding-scene-3-afronding-2_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684287032.m3u8?s=18c9f08645a82cdc1622276bbf550f6d696c1ad1',
            startAtMillis: 2500,
            lengthMillis: 17.66 * 1000
        },
        oneliners: [
            {refId: 'bewust_meemaken', timestampMillis: 2 * 1000},
        ],
        showActionAtMillis: 10 * 1000,
    },
    'm1.n.n.n.n': { // FestivalVerleiding-scene-3-afronding-3_Final_07022022
        label: 'FestivalVerleiding-scene-3-afronding-3_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684286922.m3u8?s=079f7a66bf07b90ab5e8b7903b0503494ca34fa9',
            startAtMillis: 3500,
            lengthMillis: 24.55 * 1000
        },
        showActionAtMillis: 16 * 1000,
    },
}
/*

FestivalVerleiding-scene-4_afronding-1_Final_07022022
https://player.vimeo.com/external/685475769.m3u8?s=12b6fae4ab96d88b8e527de4e23dd582a67382ab

FestivalVerleiding-scene-4_deel-1_Final_07022022
https://player.vimeo.com/external/685476414.m3u8?s=24d037e6a3be0adbfbe921c4d3648d88e8c5e827

FestivalVerleiding-scene-4_deel-2_Final_07022022
https://player.vimeo.com/external/685477069.m3u8?s=0e99795b9300d8d7847e9d8553c3b49e686bf875

FestivalVerleiding-scene-4_deel-3_Final_07022022
https://player.vimeo.com/external/685477581.m3u8?s=7470880d83d531886119e3d8957d9b47ee2bed15

FestivalVerleiding-scene-4_deel-4_Final_15022022
https://player.vimeo.com/external/685477756.m3u8?s=d8051b04d6a361d837eb0f850122494f6fb9df70

./FestivalVerleiding-scene-4_afronding-1_Final_07022022.mp4
00:00:23.32
./FestivalVerleiding-scene-4_deel-3_Final_07022022.mp4
00:00:12.16
./FestivalVerleiding-scene-4_deel-4_Final_15022022.mp4
00:00:23.83
./FestivalVerleiding-scene-4_deel-2_Final_07022022.mp4
00:00:31.10
./FestivalVerleiding-scene-4_deel-1_Final_07022022.mp4
00:00:20.48
 */
const story4: { [key: genericId]: VideoPart } = {
    'm1': { // FestivalVerleiding-scene-4_deel-1_Final_07022022
        label: 'FestivalVerleiding-scene-4_deel-1_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/685476414.m3u8?s=24d037e6a3be0adbfbe921c4d3648d88e8c5e827',
            startAtMillis: 3500,
            lengthMillis: (20.48 * 1000) - 1000
        },
        continueText: "Ik wil die sprinkhanen wel proberen.",
        next: 'm1.n',
        showActionAtMillis: -4000,
    },
    'm1.n': { // FestivalVerleiding-scene-4_deel-2_Final_07022022
        label: 'FestivalVerleiding-scene-4_deel-2_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/685477069.m3u8?s=0e99795b9300d8d7847e9d8553c3b49e686bf875',
            startAtMillis: 3300,
            lengthMillis: (31.10 * 1000) - 1000
        },
        intervention: {
            text: 'Maar ben je niet nieuwsgierig?',
            next: 'm1.n.a'
        },
        next: 'm1.n.n',
        oneliners: [
            {refId: 'slecht_voor_milieu', timestampMillis: 22000},
        ],
        showActionAtMillis: -4000
    },
    'm1.n.a': { // FestivalVerleiding-scene-4_deel-3_Final_07022022
        label: 'FestivalVerleiding-scene-4_deel-3_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/685477581.m3u8?s=7470880d83d531886119e3d8957d9b47ee2bed15',
            startAtMillis: 4000,
            lengthMillis: 12.16 * 1000
        },
        intervention: {
            text: 'Je hebt dus nog nooit een pilletje genomen?',
            next: 'm1.n.a.a'
        },
        next: 'm1.n.a.n',
        oneliners: [
            {refId: 'leuke_avond_zonder_drugs', timestampMillis: 6000},
        ],
        showActionAtMillis: -3500,
    },
    'm1.n.n': { // FestivalVerleiding-scene-4_afronding-1_Final_07022022
        label: 'FestivalVerleiding-scene-4_afronding-1_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/685475769.m3u8?s=12b6fae4ab96d88b8e527de4e23dd582a67382ab',
            startAtMillis: 3500,
            lengthMillis: 23.32 * 1000
        },
        showActionAtMillis: 15000,
    },
    'm1.n.a.a': { // FestivalVerleiding-scene-4_deel-4_Final_15022022
        label: 'FestivalVerleiding-scene-4_deel-4_Final_15022022',
        video: {
            url: 'https://player.vimeo.com/external/685477756.m3u8?s=d8051b04d6a361d837eb0f850122494f6fb9df70',
            startAtMillis: 6000,
            lengthMillis: 23.83 * 1000
        },
        continueText: "Oja, die selfie",
        next: 'm1.n.a.a.n',
        oneliners: [
            {refId: 'blijf_mezelf', timestampMillis: 13000},
        ],
    },
    'm1.n.a.n': { // FestivalVerleiding-scene-4_afronding-1_Final_07022022
        label: 'FestivalVerleiding-scene-4_afronding-1_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/685475769.m3u8?s=12b6fae4ab96d88b8e527de4e23dd582a67382ab',
            startAtMillis: 3500,
            lengthMillis: 23.32 * 1000
        },
        showActionAtMillis: 15000,
    },
    'm1.n.a.a.n': { // FestivalVerleiding-scene-4_afronding-1_Final_07022022
        label: 'FestivalVerleiding-scene-4_afronding-1_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/685475769.m3u8?s=12b6fae4ab96d88b8e527de4e23dd582a67382ab',
            startAtMillis: 3500,
            lengthMillis: 23.32 * 1000
        },
        showActionAtMillis: 15000,
    },
}
/*
FestivalVerleiding-scene-5_afronding-1_Final_07022022.mp4
https://player.vimeo.com/external/684285399.m3u8?s=7bba09934e6d69210f5b7f200f3ee5e4a2338282

FestivalVerleiding-scene-5_afronding-2_Final_07022022.mp4
https://player.vimeo.com/external/684285232.m3u8?s=69e82f558821c3ab5cdf5fca8ac7f3fa73c44e8a

FestivalVerleiding-scene-5_afronding-3_Final_07022022.mp4
https://player.vimeo.com/external/684285104.m3u8?s=22d9c180fc8ca3abd00f5d8cfd4c7649baaea53a

FestivalVerleiding-scene-5_afronding-4_Final_07022022.mp4
https://player.vimeo.com/external/684284957.m3u8?s=e22a5b48cbf09afbc6ba343ae31d9572128f58cd

FestivalVerleiding-scene-5_deel-1_Final_07022022.mp4
https://player.vimeo.com/external/684284857.m3u8?s=0f7407d0cbcaf6653c28c781102371471097f28b

FestivalVerleiding-scene-5_deel-2_Final_07022022.mp4
https://player.vimeo.com/external/684284763.m3u8?s=6ae49fc34ebef213a92ca4e63b71518648799cbe

FestivalVerleiding-scene-5_deel-3_Final_07022022.mp4
https://player.vimeo.com/external/684284639.m3u8?s=fd8eb628c9ff8557785ef8d6ac8f71b769bd64f3

FestivalVerleiding-scene-5_deel-4_Final_07022022.mp4
https://player.vimeo.com/external/684284537.m3u8?s=fb25d11ac29742e544157896d96e094e83022fb8

./FestivalVerleiding-scene-5_deel-1_Final_07022022.mp4
00:00:12.48
./FestivalVerleiding-scene-5_afronding-4_Final_07022022.mp4
00:00:26.52
./FestivalVerleiding-scene-5_deel-2_Final_07022022.mp4
00:00:12.22
./FestivalVerleiding-scene-5_deel-4_Final_07022022.mp4
00:00:14.59
./FestivalVerleiding-scene-5_afronding-2_Final_07022022.mp4
00:00:27.16
./FestivalVerleiding-scene-5_afronding-1_Final_07022022.mp4
00:00:29.03
./FestivalVerleiding-scene-5_afronding-3_Final_07022022.mp4
00:00:27.52
./FestivalVerleiding-scene-5_deel-3_Final_07022022.mp4
00:00:17.66
 */
const story5: { [key: genericId]: VideoPart } = {
    'm1': { // FestivalVerleiding-scene-5_deel-1_Final_07022022
        label: 'FestivalVerleiding-scene-5_deel-1_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684284857.m3u8?s=0f7407d0cbcaf6653c28c781102371471097f28b',
            startAtMillis: 3500,
            lengthMillis: 12.48 * 1000
        },
        continueText: "Zeker! Het is super leuk!",
        next: 'm1.n',
        showActionAtMillis: -3000,
    },
    'm1.n': { // FestivalVerleiding-scene-5_deel-2_Final_07022022
        label: 'FestivalVerleiding-scene-5_deel-2_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684284763.m3u8?s=6ae49fc34ebef213a92ca4e63b71518648799cbe',
            startAtMillis: 2000,
            lengthMillis: 12.22 * 1000
        },
        intervention: {
            text: 'Ik sla even over, het is toch niks voor mij.',
            next: 'm1.n.a'
        },
        next: 'm1.n.n',
        showActionAtMillis: -3500,
    },
    'm1.n.a': { // FestivalVerleiding-scene-5_afronding-1_Final_07022022
        label: 'FestivalVerleiding-scene-5_afronding-1_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684285399.m3u8?s=7bba09934e6d69210f5b7f200f3ee5e4a2338282',
            startAtMillis: 3000,
            lengthMillis: 29.03 * 1000
        },
        oneliners: [
            {refId: 'past_niet', timestampMillis: 3000},
        ],
        showActionAtMillis: 20000,
    },
    'm1.n.n': { // FestivalVerleiding-scene-5_deel-3_Final_07022022
        label: 'FestivalVerleiding-scene-5_deel-3_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684284639.m3u8?s=fd8eb628c9ff8557785ef8d6ac8f71b769bd64f3',
            startAtMillis: 3000,
            lengthMillis: (17.66 * 1000) - 1000
        },
        intervention: {
            text: 'We zijn hier voor elkaar en de muziek toch?',
            next: 'm1.n.n.a'
        },
        next: 'm1.n.n.n',
        showActionAtMillis: -4000,
    },
    'm1.n.n.a': { // FestivalVerleiding-scene-5_afronding-2_Final_07022022
        label: 'FestivalVerleiding-scene-5_afronding-2_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684285232.m3u8?s=69e82f558821c3ab5cdf5fca8ac7f3fa73c44e8a',
            startAtMillis: 4000,
            lengthMillis: 27.16 * 1000
        },
        oneliners: [
            {refId: 'leuk_zonder_drugs', timestampMillis: 6000},
        ],
        showActionAtMillis: 18000,
    },
    'm1.n.n.n': { // FestivalVerleiding-scene-5_deel-4_Final_07022022
        label: 'FestivalVerleiding-scene-5_deel-4_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684284537.m3u8?s=fb25d11ac29742e544157896d96e094e83022fb8',
            startAtMillis: 3500,
            lengthMillis: 14.59 * 1000
        },
        intervention: {
            text: 'Laat voor mij maar zitten.',
            next: 'm1.n.n.n.a'
        },
        next: 'm1.n.n.n.n',
        showActionAtMillis: -2600,
    },
    'm1.n.n.n.a': { // FestivalVerleiding-scene-5_afronding-3_Final_07022022
        label: 'FestivalVerleiding-scene-5_afronding-3_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684285104.m3u8?s=22d9c180fc8ca3abd00f5d8cfd4c7649baaea53a',
            startAtMillis: 4500,
            lengthMillis: 27.52 * 1000
        },
        oneliners: [
            {refId: 'geen_risico', timestampMillis: 4000},
        ],
        showActionAtMillis: 17000,
    },
    'm1.n.n.n.n': { // FestivalVerleiding-scene-5_afronding-4_Final_07022022
        label: 'FestivalVerleiding-scene-5_afronding-4_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684284957.m3u8?s=e22a5b48cbf09afbc6ba343ae31d9572128f58cd',
            startAtMillis: 4000,
            lengthMillis: 26.52 * 1000
        },
        showActionAtMillis: 17000,
    },
}
/*
FestivalVerleiding-scene-6_afronding-1_Final_07022022.mp4
https://player.vimeo.com/external/684284223.m3u8?s=c44a531c2d238be8e09ddc1bd2b21b8a4cc70192

FestivalVerleiding-scene-6_afronding-2_Final_07022022.mp4
https://player.vimeo.com/external/684284129.m3u8?s=6c8561a927649783eac7910551dfa0bdb7b63cd1

FestivalVerleiding-scene-6_afronding-3_Final_07022022.mp4
https://player.vimeo.com/external/684284043.m3u8?s=5f17161618b130d371ee84689307fb8609914dbb

FestivalVerleiding-scene-6_deel-1_Final_07022022.mp4
https://player.vimeo.com/external/684283959.m3u8?s=52ab3fae0d49af539048c52dba05830d3508876f

FestivalVerleiding-scene-6_deel-2_Final_07022022.mp4
https://player.vimeo.com/external/684283897.m3u8?s=c7f55f96ffd69ab69dc8bb68f380556037b8bcf1

FestivalVerleiding-scene-6_deel-3_Final_07022022.mp4
https://player.vimeo.com/external/684283824.m3u8?s=561b21c92de84df5c85b6e1bd4ef2c658363ed6d

./FestivalVerleiding-scene-6_afronding-3_Final_07022022.mp4
00:00:22.27
./FestivalVerleiding-scene-6_deel-3_Final_07022022.mp4
00:00:07.70
./FestivalVerleiding-scene-6_afronding-2_Final_07022022.mp4
00:00:22.78
./FestivalVerleiding-scene-6_deel-2_Final_07022022.mp4
00:00:05.23
./FestivalVerleiding-scene-6_deel-1_Final_07022022.mp4
00:00:17.79
./FestivalVerleiding-scene-6_afronding-1_Final_07022022.mp4
00:00:20.95

 */
const story6: { [key: genericId]: VideoPart } = {
    'm1': {  // FestivalVerleiding-scene-6_deel-1_Final_07022022
        label: 'FestivalVerleiding-scene-6_deel-1_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684283959.m3u8?s=52ab3fae0d49af539048c52dba05830d3508876f',
            startAtMillis: 3500,
            lengthMillis: 17.79 * 1000
        },
        continueText: "Thanks!",
        next: 'm1.n',
        showActionAtMillis: -2000,
    },
    'm1.n': { // FestivalVerleiding-scene-6_deel-2_Final_07022022
        label: 'FestivalVerleiding-scene-6_deel-2_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684283897.m3u8?s=c7f55f96ffd69ab69dc8bb68f380556037b8bcf1',
            startAtMillis: 0,
            lengthMillis: 5.23 * 1000
        },
        intervention: {
            text: 'Ik zou even rustig aan doen.',
            next: 'm1.n.a'
        },
        next: 'm1.n.n',
        showActionAtMillis: -2000,
    },
    'm1.n.n': { // FestivalVerleiding-scene-6_deel-3_Final_07022022
        label: 'FestivalVerleiding-scene-6_deel-3_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684283824.m3u8?s=561b21c92de84df5c85b6e1bd4ef2c658363ed6d',
            startAtMillis: 1300,
            lengthMillis: 7.70 * 1000
        },
        intervention: {
            text: 'Ik weet niet hoe dat spul bij mij uit gaat pakken.',
            next: 'm1.n.n.a'
        },
        next: 'm1.n.n.n',
        showActionAtMillis: -3500,
    },
    'm1.n.a': { // FestivalVerleiding-scene-6_afronding-1_Final_07022022
        label: 'FestivalVerleiding-scene-6_afronding-1_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684284223.m3u8?s=c44a531c2d238be8e09ddc1bd2b21b8a4cc70192',
            startAtMillis: 4000,
            lengthMillis: 20.95 * 1000
        },
        oneliners: [
            {refId: 'blijf_in_controle', timestampMillis: 6500},
        ],
        showActionAtMillis: 12000,
    },
    'm1.n.n.a': { // FestivalVerleiding-scene-6_afronding-2_Final_07022022
        label: 'FestivalVerleiding-scene-6_afronding-2_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684284129.m3u8?s=6c8561a927649783eac7910551dfa0bdb7b63cd1',
            startAtMillis: 4000,
            lengthMillis: 22.78 * 1000
        },
        oneliners: [
            {refId: 'geen_zin_in_risico', timestampMillis: 6000},
        ],
        showActionAtMillis: 13000,
    },
    'm1.n.n.n': { // FestivalVerleiding-scene-6_afronding-3_Final_07022022
        label: 'FestivalVerleiding-scene-6_afronding-3_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684284043.m3u8?s=5f17161618b130d371ee84689307fb8609914dbb',
            startAtMillis: 2200,
            lengthMillis: 22.27 * 1000
        },
        showActionAtMillis: 12000,
    },
}
/*
FestivalVerleiding-scene-7_afronding-1_Final_07022022.mp4
https://player.vimeo.com/external/684274835.m3u8?s=8218d9a166ba67b1891bffffc59e2392c8f3c622

FestivalVerleiding-scene-7_afronding-2_Final_07022022.mp4
https://player.vimeo.com/external/684274765.m3u8?s=279f9f06749d5322fe8aad99c69a1c59af6ce477

FestivalVerleiding-scene-7_afronding-3_Final_07022022.mp4
https://player.vimeo.com/external/684274636.m3u8?s=4b08ea6adf9f1559eee84388c158f5231e33d68d

FestivalVerleiding-scene-7_deel-1_Final_07022022.mp4
https://player.vimeo.com/external/684274537.m3u8?s=5437aa211eecebd22194b1bb83a8140d0a58a431

FestivalVerleiding-scene-7_deel-2_Final_07022022.mp4
https://player.vimeo.com/external/684274412.m3u8?s=faffb18cc024c8478a8088ae285ae0f52aeafaf6

FestivalVerleiding-scene-7_deel-3_Final_07022022.mp4
https://player.vimeo.com/external/684274318.m3u8?s=5faf535d85502fa545e177eed409776a3905a121

./FestivalVerleiding-scene-7_afronding-3_Final_07022022.mp4
00:00:25.28
./FestivalVerleiding-scene-7_deel-1_Final_07022022.mp4
00:00:16.06
./FestivalVerleiding-scene-7_deel-2_Final_07022022.mp4
00:00:15.96
./FestivalVerleiding-scene-7_deel-3_Final_07022022.mp4
00:00:13.55
./FestivalVerleiding-scene-7_afronding-2_Final_07022022.mp4
00:00:20.82
./FestivalVerleiding-scene-7_afronding-1_Final_07022022.mp4
00:00:19.67
 */
const story7: { [key: genericId]: VideoPart } = {
    'm1': { // FestivalVerleiding-scene-7_deel-1_Final_07022022
        label: 'FestivalVerleiding-scene-7_deel-1_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684274537.m3u8?s=5437aa211eecebd22194b1bb83a8140d0a58a431',
            startAtMillis: 3500,
            lengthMillis: 16.06 * 1000
        },
        continueText: "Nice",
        next: 'm1.n',
        showActionAtMillis: -2000,
    },
    'm1.n': { // FestivalVerleiding-scene-7_deel-2_Final_07022022
        label: 'FestivalVerleiding-scene-7_deel-2_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684274412.m3u8?s=faffb18cc024c8478a8088ae285ae0f52aeafaf6',
            startAtMillis: 4500,
            lengthMillis: (15.96 * 1000) - 1000
        },
        intervention: {
            text: 'Ik ga zo slapen denk ik.',
            next: 'm1.n.a'
        },
        next: 'm1.n.n',
        showActionAtMillis: -3000,
    },
    'm1.n.a': { // FestivalVerleiding-scene-7_afronding-1_Final_07022022
        label: 'FestivalVerleiding-scene-7_afronding-1_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684274835.m3u8?s=8218d9a166ba67b1891bffffc59e2392c8f3c622',
            startAtMillis: 3000,
            lengthMillis: 19.67 * 1000
        },
        oneliners: [
            {refId: 'ga_slapen', timestampMillis: 4000},
        ],
        showActionAtMillis: 11000,
    },
    'm1.n.n': { // FestivalVerleiding-scene-7_deel-3_Final_07022022
        label: 'FestivalVerleiding-scene-7_deel-3_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684274318.m3u8?s=5faf535d85502fa545e177eed409776a3905a121',
            startAtMillis: 2500,
            lengthMillis: (13.55 * 1000) - 1000
        },
        intervention: {
            text: 'Thanks, ik eet liever iets om energie te krijgen.',
            next: 'm1.n.n.a'
        },
        next: 'm1.n.n.n',
        showActionAtMillis: -3000,
    },
    'm1.n.n.a': { // FestivalVerleiding-scene-7_afronding-2_Final_07022022
        label: 'FestivalVerleiding-scene-7_afronding-2_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684274765.m3u8?s=279f9f06749d5322fe8aad99c69a1c59af6ce477',
            startAtMillis: 1000,
            lengthMillis: 20.82 * 1000
        },
        oneliners: [
            {refId: 'eet_voor_energie', timestampMillis: 2000},
        ],
        showActionAtMillis: 12000,
    },
    'm1.n.n.n': { // FestivalVerleiding-scene-7_afronding-3_Final_07022022
        label: 'FestivalVerleiding-scene-7_afronding-3_Final_07022022',
        video: {
            url: 'https://player.vimeo.com/external/684274636.m3u8?s=4b08ea6adf9f1559eee84388c158f5231e33d68d',
            startAtMillis: 4300,
            lengthMillis: 25.28 * 1000
        },
        showActionAtMillis: 15000,
    },
}




const config:AppContent = {
    oneliners:
        {
            // 1
            'doe_niet_drugs': {
                text: 'Ik doe niet aan drugs',
                styling: styles.scene1
            },
            'lekker_zonder_drugs': {
                text: 'Ik ga lekker zonder drugs',
                styling: styles.scene1
            },

            // 2
            'fit_voor_team': {
                text: 'Mijn team is blij als ik niet gebruik.',
                styling: styles.scene1
            },
            'geen_dinsdagdip': {
                text: 'Ik heb geen zin in een dinsdagdip.',
                styling: styles.scene1
            },

            // 3
            'nee_dank_je': {
                text: 'Nee, dank je',
                styling: styles.scene3
            },
            'bewust_meemaken': {
                text: 'Ik wil het festival bewust meemaken.',
                styling: styles.scene3
            },

            // 4
            'slecht_voor_milieu': {
                text: 'Drugsproductie is slecht voor het milieu.',
                styling: styles.scene4
            },
            'leuke_avond_zonder_drugs': {
                text: 'Ik heb een even leuke avond zonder drugs.',
                styling: styles.scene4
            },
            'blijf_mezelf': {
                text: 'Ik blijf liever mijzelf.',
                styling: styles.scene4
            },

            // 5
            'past_niet': {
                text: 'Drugs past niet bij mij',
                styling: styles.scene5
            },
            'leuk_zonder_drugs': {
                text: 'Ik heb het leuk zonder drugs.',
                styling: styles.scene5
            },
            'geen_risico': {
                text: 'Ik wil geen risico lopen.',
                styling: styles.scene5
            },

            // 6
            'blijf_in_controle': {
                text: 'Ik blijf in controle door niet te gebruiken.',
                styling: styles.scene3
            },
            'geen_zin_in_risico': {
                text: 'Ik weet niet hoe de drugs zullen uitpakken. Ik heb geen zin in risico\'s',
                styling: styles.scene3
            },

            // 7
            'ga_slapen': {
                text: 'Ik ga zo slapen, want dat is wat ik wil.',
                styling: styles.scene7
            },
            'eet_voor_energie': {
                text: 'Ik eet liever iets om weer energie te krijgen.',
                styling: styles.scene7
            },
        }
    ,
    scenes: [
        // 7 in totaal?
        {
            label: '1 - 1 WEEK VOORAF',
            leaderText: "1 WEEK\nVOORAF",
            finalText: "Het lijkt soms anders, maar wist je dat het grootste deel van de Nederlandse jongeren nooit drugs gebruikt?\n" +
                "\n" +
                "Als je van te voren zegt dat je niet wilt gaan gebruiken, is dat voor iedereen duidelijk! ",
            style: styles.scene1,
            videos: story1,
            startButtonPosition: {left: '294rem', top: '225rem'},
        },
        {
            label: '2 - ZOË – 14:00 UUR',
            leaderText: "14:00",
            finalText: "Door niet te gebruiken blijf je fit en maak je een festival bewust mee.\n" +
                "\n" +
                "Wat zijn voor jou voordelen van niet gebruiken?",
            style: styles.scene1,
            videos: story2,
            startButtonPosition: {left: '321rem', top: '252rem'},
        },
        {
            label: '3 - MAUD – 16:00 UUR',
            leaderText: "16:00",
            finalText: "Wie van je goede vrienden gebruikt  geen drugs?\n\nHeb je wel eens gevraagd waarom?",
            style: styles.scene3,
            videos: story3,
            startButtonPosition: {left: '83rem', top: '420rem'},
        },
        {
            label: '4 - CHARLEY & ERIK – 18:00 UUR',
            leaderText: "18:00",
            finalText: "Bij het maken van synthetische drugs komen er schadelijke stoffen vrij die vaak in de natuur gedumpt worden.\n\n" +
                // "Dit heeft gevolgen voor dieren, planten en grondwater.\n\n" + // TODO tijdelijk uit
                "Wat vind jij daarvan?",
            style: styles.scene4,
            videos: story4,
            startButtonPosition: {left: '44rem', top: '225rem'},
        },
        {
            label: '5 - ZOË & ERIK – 23:00 UUR',
            leaderText: "23:00",
            finalText: "Wat zou voor jou een goede reden zijn om geen drugs te gebruiken?\n" +
                "\n" +
                "Spreek met jezelf af om niet te gebruiken en geniet van je festival!",
            style: styles.scene5,
            videos: story5,
            startButtonPosition: {left: '168rem', top: '200rem'},
        },
        {
            label: '6 - MAUD – 02:00 UUR',
            leaderText: "02:00",
            finalText: "Drugsgebruik kan zorgen voor controleverlies en brengt altijd risico's met zich mee.\n" +
                "\n" +
                "Wanneer heb jij graag de controle?",
            style: styles.scene3,
            videos: story6,
            startButtonPosition: {left: '109rem', top: '447rem'},
        },
        {
            label: '7 - RAFAEL – 04:00 UUR',
            leaderText: "04:00",
            finalText: "Goede muziek, energy drink en een hapje eten kunnen je helpen als je de after wilt meepakken. \n" +
                "\n" +
                "Hoe pep jij jezelf op?",
            style: styles.scene7,
            videos: story7,
            startButtonPosition: {left: '263rem', top: '420rem'},
        },
        // {
        //     label: 'x - test – 04:00 UUR',
        //     leaderText: "08U",
        //     finalText: "Goede muziek, energy drink en een hapje eten kunnen je helpen als je de after wilt meepakken. \n" +
        //         "\n" +
        //         "Hoe pep jij jezelf op?",
        //     style: styles.scene7,
        //     videos: {
        //         'm1': {
        //             label: 'urlGreen',
        //             video: {
        //                 url: urlGreen,
        //                 startAtMillis: 0,
        //                 lengthMillis: 8 * 1000
        //             },
        //             oneliners: [
        //                 {
        //                     refId: 'doe_niet_drugs',
        //                     timestampMillis: 2 * 1000
        //                 },
        //             ],
        //             showActionAtMillis: -3000,
        //             intervention: {
        //                 text: 'Nee, dank je',
        //                 next: 'm1.a'
        //             },
        //             next: 'm1.n',
        //         },
        //         'm1.a': {
        //             label: 'urlPurple',
        //             video: {
        //                 url: urlPurple,
        //                 startAtMillis: 0,
        //                 lengthMillis: 8 * 1000
        //             },
        //         },
        //         'm1.n': {
        //             label: 'urlBlack',
        //             video: {
        //                 url: urlBlack,
        //                 startAtMillis: 0,
        //                 lengthMillis: 8 * 1000
        //             },
        //         }
        //     },
        //     startButtonPosition: {left: '178rem', top: '275rem'},
        // }
    ]
};

export default config;