import React from "react";
import "./InfoScreen.css"

export default function InfoScreen(props: {onClose: () => void }) {

    return <div className='info-screen'>
        <div className='app-title' onClick={() => {}}>Festival<br/>Oneliners</div>
        <div className='speaker' onClick={() => {}}></div>

        <div className='info-screen__body'>
            <h3>Hoe werkt de game</h3>
            <p>Tijdens het kijken van de video’s kun je oneliners verzamelen. Kijk alle video’s en klik op de antwoorden om alle oneliners te verzamelen. Je kunt ook oneliners verzamelen door te luisteren naar je vrienden.</p>

            <h3>Over de game</h3>
            <p>Soms lijkt het alsof iedereen drugs gebruikt. Dat klopt niet: de meeste jongeren gebruiken nooit drugs. Met deze game willen we jongeren laten nadenken over drugsgebruik en hen handvatten geven om hun eigen keuzes te maken en deze te uiten.</p>

            <p>Deze game is ontwikkeld door het Trimbos-instituut in samenwerking met IJsfontein en met financiering van het Ministerie van Volksgezondheid, Welzijn en Sport (VWS). De bedoeling is met deze game jongeren en jongvolwassenen die geen uitgaansdrugs gebruiken te ondersteunen.</p>

            <h2>Uitvoerende partijen</h2>

            <h3>Trimbos-instituut</h3>
            <p>Het Trimbos-instituut is het onafhankelijke landelijke kennisinstituut voor alcohol, tabak, drugs en mentale gezondheid.</p>

            <h3>IJsfontein</h3>
            <p>IJsfontein ontwikkelt spelend leren vanuit de overtuiging dat mensen van nature nieuwsgierig en intrinsiek gemotiveerd zijn om zichzelf te ontwikkelen.</p>
        </div>
        <button className='info-screen__close' onClick={() => {props.onClose()}}>close</button>
    </div>
}